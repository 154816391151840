const entity = {
  name: {
    singular: 'Mailing',
    plural: 'Mailings'
  },
  collection: 'mailings',
  tableHeaders: [
    { text: 'Título', value: 'title' },
    { text: 'Descrição', value: 'description' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    title: null,
    collection: '',
    description: '',
    files: []
  },
  schema: {
    groups: [
      {
        name: 'Detalhes',
        fields: [
          {
            type: 'text-field',
            label: 'Título',
            model: 'title',
            required: true
          },
          {
            type: 'text-field',
            label: 'Collection',
            model: 'collection',
            required: true
          },
          {
            type: 'text-field',
            label: 'Descrição',
            model: 'description',
            required: true
          },
          {
            type: 'single-file',
            label: 'Arquivos',
            model: 'files',
            required: true,
            allowedFileTypes: 'text/csv',
            allowedExtensions: 'csv'
          }
        ]
      },
      {
        name: 'Collection',
        list: 'collection'
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/mailings',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'mailings',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: "mailings" */ '../../../modules/mailings/List.vue')
  }, {
    path: '/mailings/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'mailings',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: "mailings" */ '../../../modules/mailings/Form.vue')
  }, {
    path: '/mailings/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'mailings',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: "mailings" */ '../../../modules/mailings/Form.vue')
  }
]
