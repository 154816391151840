<template>
  <v-menu
    style="z-index: 10000"
    class="m-date__container"
    v-model="menu2"
    transition="scale-transition"
    :close-on-content-click="false"
    offset-y
    :ref="'mdate_' + fieldData.model"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-if="showClearButton"
        class="m-date__clear"
        @click="clear()"
      >clear</v-icon>
      <v-text-field
        class="m-date__input"
        v-model="computedDateFormatted"
        :label="fieldData.label"
        :hint="fieldData.hint"
        persistent-hint
        append-icon="event"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      range
      @input="menu2 = false"
    />
  </v-menu>
</template>
<script>
import mMixin from './mixin'

export default {
  name: 'm-date',
  mixins: [mMixin],
  data () {
    return {
      dates: ['2019-09-10', '2019-09-20'],
      date: '',
      dateFormatted: '',
      menu1: false,
      menu2: false
    }
  },

  mounted () {
    this.$nextTick(function () {
      if (this.proxyValue) {
        const originalDate = (new Date(this.proxyValue))
        let formattedDate = new Date(originalDate.getTime() + 10800000)
        formattedDate = formattedDate.getFullYear() + '-' + (formattedDate.getMonth() + 1) + '-' + (formattedDate.getDate())
        this.date = formattedDate
      }
    })
  },

  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    showClearButton () {
      return this.date && this.date.length > 0
    }
  },

  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
      this.proxyValue = val
    }
  },

  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const divider = date.includes('-') ? '-' : '/'

      const [day, month, year] = date.split(divider)
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    clear () {
      this.date = ''
    }
  }
}
</script>
<style lang="scss">
.m-date__container {
  position: relative;
}

.m-date {
  &__clear {
    top: 30px;
    z-index: 1000;
    position: absolute !important;
  }

  &__input {
    z-index: 0;

    .v-text-field__slot input {
      text-align: right;
    }
  }
}

.field-date-picker {
  position: relative;
}
</style>
