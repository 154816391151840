import slugify from 'slugify'
import { firebaseStorage } from '../plugins/firebase'
import { localPersistence } from '@/services/LocalPersistenceService'

const accountId = localPersistence.get('mstrAcId')

const uploadService = (collection) => {
  const baseDirectory = `/Maestro/${accountId}/${collection}/`

  const uploadSingleFile = async (file) => {
    const ref = firebaseStorage.ref().child(baseDirectory)

    const slugifiedName = slugify(file.name, { lower: true })
    const fileName = `${(+new Date())}-${slugifiedName}`

    const task = ref.child(fileName).put(file.file)
    const response = await task
    const url = await response.ref.getDownloadURL()

    const newFile = {
      size: file.size,
      url: url,
      path: baseDirectory + fileName,
      uploaded: true
    }

    return newFile
  }

  const uploadFiles = async (files) => {
    const uploadedFiles = []
    const ref = firebaseStorage.ref().child(baseDirectory)

    const uploads = files.map(async (file, idx) => {
      if (file.uploaded) {
        uploadedFiles.push(file)
      } else {
        const fileExtension = file.name.split('.').pop()
        const fileName = `${(+new Date())}-${collection}--${idx}.${fileExtension}`
        const task = ref.child(fileName).put(file.file)
        const response = await task

        // console.log(response)

        const url = await response.ref.getDownloadURL()

        const newFile = {
          id: file.id,
          size: file.size,
          url: url,
          path: baseDirectory + fileName,
          title: '',
          subtitle: '',
          body: '',
          link: '',
          position: idx,
          uploaded: true
        }

        uploadedFiles.push(newFile)

        return newFile
      }
    })

    await Promise.all(uploads)

    return uploadedFiles
  }

  const uploadMediaManager = async (files) => {
    const dir = collection
    const uploadedFiles = []
    const ref = firebaseStorage.ref().child(dir)

    const uploads = files.map(async (file, idx) => {
      if (file.uploaded) {
        uploadedFiles.push(file)
      } else {
        const fileName = slugify(file.name, { lower: true })
        const task = ref.child(fileName).put(file.file)
        const response = await task
        const url = await response.ref.getDownloadURL()

        const newFile = {
          id: file.id,
          size: file.size,
          url: url,
          path: dir + fileName,
          title: file.name,
          subtitle: '',
          body: '',
          link: '',
          position: idx,
          uploaded: true
        }

        uploadedFiles.push(newFile)

        return newFile
      }
    })

    await Promise.all(uploads)

    return uploadedFiles
  }

  const deleteFile = async (path) => {
    const ref = firebaseStorage.ref().child(path)
    const task = ref.delete()
    const response = await task
    return response
  }

  return {
    uploadSingleFile,
    uploadFiles,
    uploadMediaManager,
    deleteFile
  }
}

export default uploadService
