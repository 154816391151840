const entity = {
  name: {
    singular: 'Cliente',
    plural: 'Clientes'
  },
  collection: 'clients',
  tableHeaders: [
    { text: 'Nome/Razão Social', value: 'name' },
    { text: 'CPF/CNPJ', value: 'document' },
    { text: 'Telefone', value: 'landline' },
    { text: 'Celular', value: 'cellphone' },
    { text: 'Email', value: 'email' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    personType: 'pessoa_juridica',
    name: '',
    email: '',
    document: '',
    cellphone: '',
    landline: '',
    zipcode: '',
    city: '',
    state: '',
    address: '',
    addressNumber: '',
    address2: '',
    neighborhood: '',
    country: '',
    buyDate: ''
  },
  schema: {
    groups: [
      {
        name: 'Informações Gerais',
        fields: [
          {
            type: 'radio',
            label: 'Pessoa',
            model: 'personType',
            items: [
              { title: 'Física', _id: 'fisica' },
              { title: 'Jurídica', _id: 'juridica' }
            ],
            size: 'xs12 sm12 md6 lg6'
          },
          {
            type: 'text-field',
            label: 'CPF/CNPJ',
            model: 'document',
            size: 'xs12 sm12 md6 lg6'
          },
          {
            type: 'text-field',
            label: 'Nome/Razão social',
            model: 'name',
            size: 'xs12 sm12 md12 lg12'
          },
          {
            type: 'text-field',
            label: 'Email',
            model: 'email',
            size: 'xs12 sm12 md6 lg6'
          },
          {
            type: 'text-field',
            label: 'Telefone',
            model: 'landline',
            size: 'xs12 sm12 md3 lg3'
          },
          {
            type: 'text-field',
            label: 'Celular',
            model: 'cellphone',
            size: 'xs12 sm12 md3 lg3'
          },
          {
            type: 'text-field',
            label: 'CEP',
            model: 'zipcode',
            size: 'xs12 sm12 md3 lg3'
          },
          {
            type: 'text-field',
            label: 'Endereço',
            model: 'address',
            size: 'xs12 sm12 md8 lg8'
          },
          {
            type: 'text-field',
            label: 'Número',
            model: 'number',
            size: 'xs12 sm12 md1 lg1'
          },
          {
            type: 'text-field',
            label: 'Complemento',
            model: 'address2',
            size: 'xs12 sm12 md3 lg3'
          },
          {
            type: 'text-field',
            label: 'Bairro',
            model: 'neighborhood',
            size: 'xs12 sm12 md3 lg3'
          },
          {
            type: 'text-field',
            label: 'Cidade',
            model: 'city',
            size: 'xs12 sm12 md5 lg5'
          },
          {
            type: 'text-field',
            label: 'Estado',
            model: 'state',
            size: 'xs12 sm12 md1 lg1'
          },
          {
            type: 'text-field',
            label: 'País',
            model: 'country'
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/clients',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'clients',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/clients/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'clients',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/clients/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'clients',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }
]
