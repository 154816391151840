const entity = {
  name: {
    singular: 'Conteúdo',
    plural: 'Conteúdos'
  },
  styles: {
    gridTemplateColumns: '30% 1fr',
    gridTemplateAreas: '"main secondary"'
  },
  collection: 'contents',
  tableHeaders: [
    { text: 'Título', value: 'title', type: 'string' },
    { text: 'Sections', value: 'sections', type: 'arrayOfParents' },
    { text: 'Url Amigável', value: 'slug', type: 'string' },
    { text: 'Publicado', value: 'published', type: 'boolean' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    sections: [],
    title: '',
    summary: '',
    fileTeaser: '',
    fileTeaserMobile: '',
    body: '',
    styles: '',
    gallery: [],
    slug: '',
    dateIn: null,
    dateOut: null,
    pudlished: true
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      {
        type: 'select',
        label: 'Seções',
        model: 'sections',
        multiple: true,
        collection: 'sections',
        size: 'xs12 sm12 md12 lg12'
      },
      {
        type: 'url',
        label: 'Url amigável',
        model: 'slug',
        required: true
      },
      {
        type: 'date-picker',
        label: 'Data de Entrada',
        hint: 'Deixe em branco caso não tenha data limite.',
        model: 'dateIn',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'date-picker',
        label: 'Data de Saída',
        hint: 'Deixe em branco caso não tenha data limite.',
        model: 'dateOut',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Conteúdo',
        fields: [
          {
            type: 'text-field',
            label: 'Título',
            model: 'title',
            required: true
          },
          {
            type: 'text-field',
            label: 'Resumo',
            model: 'summary',
            required: false
          },
          {
            type: 'editor',
            label: 'Conteúdo',
            model: 'body',
            required: false
          },
          {
            type: 'code-editor',
            language: 'css',
            label: 'Estilos',
            model: 'styles',
            required: false
          }
        ]
      },
      {
        name: 'Imagens',
        fields: [
          {
            type: 'single-file',
            label: 'Imagem do Topo',
            model: 'fileTeaser',
            hideInput: true,
            size: 'xs12 sm12 md6 lg6'
          },
          {
            type: 'single-file',
            label: 'Mobile',
            model: 'fileTeaserMobile',
            hideInput: true,
            size: 'xs12 sm12 md6 lg6'
          }
        ]
      },
      {
        name: 'Galeria',
        fields: [
          {
            type: 'gallery-cards',
            label: 'Galeria de Imagens',
            model: 'gallery'
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: true,
    validateAfterChanged: true,
    validateAsync: true
  }
}

export default [
  {
    path: '/contents',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'contents',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/contents/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'contents',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/contents/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'contents',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }
]
