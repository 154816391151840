import Vue from 'vue'
import Router from 'vue-router'
import { localPersistence } from '../services/LocalPersistenceService'

import accounts from './routes/base/accounts'
import auth from './routes/base/auth'
import banners from './routes/cms/banners'
import campaigns from './routes/marketing/campaigns'
import clients from './routes/base/clients'
import contacts from './routes/marketing/contacts'
import contents from './routes/cms/contents'
import courses from './routes/cms/courses'
import events from './routes/events/events'
import files from './routes/base/files'
import forbidden from './routes/base/forbidden'
import forms from './routes/forms/forms'
import galleries from './routes/cms/galleries'

import brokers from './routes/loads/brokers'
import carriers from './routes/loads/carriers'
import drivers from './routes/loads/drivers'
import loads from './routes/loads/loads'

import mailings from './routes/marketing/mailings'
import main from './routes/base/main'
import menus from './routes/cms/menus'
import modules from './routes/base/modules'
import pages from './routes/cms/pages'
import parents from './routes/school/parents'
import sections from './routes/cms/sections'
import serviceOrders from './routes/services/serviceOrders'
import students from './routes/school/students'
import users from './routes/base/users'

import store from '../store/store'

Vue.use(Router)

const router = new Router({
  routes: [
    ...accounts,
    ...auth,
    ...banners,
    ...brokers,
    ...campaigns,
    ...carriers,
    ...clients,
    ...contacts,
    ...contents,
    ...courses,
    ...drivers,
    ...events,
    ...files,
    ...forbidden,
    ...forms,
    ...galleries,
    ...loads,
    ...mailings,
    ...main,
    ...menus,
    ...modules,
    ...pages,
    ...parents,
    ...sections,
    ...serviceOrders,
    ...students,
    ...users
  ]
})

router.beforeEach(async (to, from, next) => {
  let isAuthenticated = store.getters['authentication/isAuthenticated']
  let user = store.getters['authentication/currentUser']

  if (!user && localPersistence.get('mstrAuthUsr') && localPersistence.get('mstrToken')) {
    store.dispatch('authentication/getFromLocalPersistence')
    user = localPersistence.get('mstrAuthUsr')
    user.token = localPersistence.get('mstrToken')
    isAuthenticated = true
  }

  const requiresAuth = to.matched.some(({ meta }) => {
    return meta.requiresAuth
  })

  const requiresPermission = to.matched.some(({ meta }) => {
    return meta.requiresPermissionTo
  })

  // Requires auth and user aren't logged in
  if (requiresAuth && (!user || !user.token)) {
    // console.log('Login user out!')
    store.dispatch('authentication/logout')
    return next({ name: 'login' })
  }

  // Requires auth, user are logged in, but isn't authorized to that module
  if (requiresPermission) {
    const collection = to.meta.requiresPermissionTo
    const modules = JSON.parse(localPersistence.get('mstrModules'))
    const isAllowed = Boolean(modules.filter(item => item.code === collection).length)
    if (!isAllowed) return next({ name: 'forbidden' })
  }

  if (to.path === '/logout') {
    store.dispatch('authentication/logout')
    return next({ name: 'login' })
  }

  if (requiresAuth) {
    if (isAuthenticated) {
      // if (to.path !== from.path) store.dispatch('authentication/refreshCurrentUser')
      return next()
    }

    const { name, fullPath } = to
    const query = name !== undefined ? { redirect: name } : { path: fullPath }

    return next({
      name: 'login',
      query
    })
  }

  return next()
})

export { router }
