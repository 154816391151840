var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.fieldData.inputMask === 'money')?_c('v-text-field-money',{ref:_vm.fieldData.model,staticClass:"m-input__money",attrs:{"label":_vm.fieldData.label,"required":_vm.fieldData.required,"properties":{
    prefix: _vm.fieldData.inputMaskOptions.prefix || 'R$',
    readonly: _vm.fieldData.inputMaskOptions.readonly || false,
    disabled: _vm.fieldData.inputMaskOptions.disabled || false,
    outlined: _vm.fieldData.inputMaskOptions.outlined || false,
    clearable: _vm.fieldData.inputMaskOptions.clearable || false,
    placeholder: _vm.fieldData.inputMaskOptions.placeholder || ' ',
  },"options":{
    locale: _vm.fieldData.inputMaskOptions.locale || 'pt-BR',
    length: _vm.fieldData.inputMaskOptions.length || 11,
    precision: _vm.fieldData.inputMaskOptions.precision || 6,
    empty: _vm.fieldData.inputMaskOptions.empty || null,
  }},model:{value:(_vm.proxyValue),callback:function ($$v) {_vm.proxyValue=$$v},expression:"proxyValue"}}):(_vm.fieldData.inputMask === 'percent')?_c('v-text-field-percent',{ref:_vm.fieldData.model,attrs:{"label":_vm.fieldData.label,"required":_vm.fieldData.required,"properties":{
    suffix: '%',
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    placeholder: '',
  },"options":{
    locale: 'pt-BR',
    length: 3,
    precision: 2,
    empty: null,
  }},model:{value:(_vm.proxyValue),callback:function ($$v) {_vm.proxyValue=$$v},expression:"proxyValue"}}):(_vm.fieldData.inputMask === 'integer')?_c('v-text-field-integer',{ref:_vm.fieldData.model,attrs:{"label":_vm.fieldData.label,"required":_vm.fieldData.required,"properties":{
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    placeholder: '',
  },"options":{
    inputMask: '#########',
    outputMask: '#########',
    empty: null,
    applyAfter: false,
  }},model:{value:(_vm.proxyValue),callback:function ($$v) {_vm.proxyValue=$$v},expression:"proxyValue"}}):(_vm.fieldData.inputMask && _vm.fieldData.inputMask.includes('##'))?_c('v-text-field-simplemask',{ref:_vm.fieldData.model,attrs:{"label":_vm.fieldData.label,"required":_vm.fieldData.required,"properties":{
    prefix: '',
    suffix: '',
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    placeholder: '',
  },"options":{
    inputMask: _vm.fieldData.inputMask,
    outputMask: _vm.fieldData.outputMask,
    empty: null,
    applyAfter: false,
    alphanumeric: true,
    lowerCase: false,
  }},on:{"blur":_vm.callback},model:{value:(_vm.proxyValue),callback:function ($$v) {_vm.proxyValue=$$v},expression:"proxyValue"}}):_c('v-text-field',{ref:_vm.fieldData.model,attrs:{"label":_vm.fieldData.label,"hint":_vm.fieldData.hint,"disabled":_vm.fieldData.readOnly},model:{value:(_vm.proxyValue),callback:function ($$v) {_vm.proxyValue=$$v},expression:"proxyValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }