const entity = {
  name: {
    singular: 'Ordem de Serviço',
    plural: 'Ordens de Serviço'
  },
  collection: 'service-orders',
  tableHeaders: [
    { text: 'Número', value: 'number' },
    { text: 'Cliente', value: 'client.name' },
    { text: 'Início', value: 'startDate', type: 'date' },
    { text: 'Entrega', value: 'endDate', type: 'date' },
    { text: 'Status', value: 'status' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    client: '',
    number: '',
    notes: '',
    startDate: '',
    endDate: '',
    status: ''
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      {
        type: 'select',
        label: 'Cliente',
        model: 'client',
        multiple: false,
        collection: 'clients',
        size: 'xs12 sm12 md12 lg12'
      },
      {
        type: 'text-field',
        label: 'Número da Ordem de Serviço',
        model: 'number',
        size: 'xs12 sm12 md12 lg12'
      },
      {
        type: 'date-picker',
        label: 'Início',
        model: 'startDate',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'date-picker',
        label: 'Término',
        hint: 'Para OSs em aberto, insira a estimativa.',
        model: 'endDate',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'radio',
        label: 'Status',
        model: 'status',
        items: [
          { title: 'Em análise', _id: 'investigating' },
          { title: 'Aguardando aprovação', _id: 'waiting_approval' },
          { title: 'Em execução', _id: 'in_progress' },
          { title: 'Concluído', _id: 'finished' }
        ],
        size: 'xs12 sm12 md6 lg6'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Detalhes da OS',
        fields: [
          {
            type: 'editor',
            label: 'Notas',
            model: 'notes',
            required: false
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/service-orders',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'serviceOrders',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/service-orders/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'serviceOrders',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/service-orders/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'serviceOrders',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }
]
