<template>
  <v-app :class="{ '--is-signedoff': !isAuthenticated }">
    <m-messages />
    <template v-if="isAuthenticated">
      <main-header @expand="toggleMenu" />
    </template>
    <v-container class="--boxed">
      <v-content :class="classes">
        <router-view />
      </v-content>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import MainHeader from './components/Layout/MainHeader'

export default {
  name: 'App',
  components: {
    MainHeader
  },
  data () {
    return {
      isMenuExpanded: false
    }
  },
  computed: {
    ...mapGetters(
      'authentication',
      ['isAuthenticated']
    ),
    moduleClass () {
      const module = this.$route.fullPath.split('/')[1]
      return 'module-' + module
    },
    classes () {
      return ['app__container', this.moduleClass, {
        'menu-expanded': this.isMenuExpanded,
        'menu-closed': this.isAuthenticated && !this.isMenuExpanded
      }]
    }
  },
  methods: {
    toggleMenu (val) {
      this.isMenuExpanded = val
    }
  }
}
</script>

<style lang="scss">
  @import './assets/styles/main.scss';
  .v-application--wrap {
    background-color: #eff3f6;
  }

  #app {
    &.--is-signedoff {
      background-image: url('./assets/images/stripes-background.jpg');
      background-size: cover;

      .v-application--wrap {
        background-color: transparent;
      }
    }
  }

  .app__container {
    height: 100%;
    &.menu-expanded {
      margin-left: 193px;
      width: calc(100% - 180px);
    }

    &.menu-closed {
      margin-left: 69px;
      width: calc(100% - 56px);
    }
  }

  .--boxed {
    max-width: 1440px !important;
    margin: 0 auto;
    height: calc(100vh - 60px);
    padding: 0 !important;

    .--is-signedoff & {
      height: 100vh;
    }
  }
</style>
