const entity = {
  name: {
    singular: 'Galeria',
    plural: 'Galerias'
  },
  collection: 'galleries',
  tableHeaders: [
    { text: 'Título', value: 'title' },
    { text: 'Chave', value: 'key' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    title: null,
    key: '',
    items: [],
    published: false,
    dateIn: null,
    dateOut: null
  },
  config: {
    name: 'Config',
    fields: [
      {
        type: 'date-picker',
        label: 'Data de Entrada',
        model: 'dateIn',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'date-picker',
        label: 'Data de Saída',
        model: 'dateOut',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado'
      }
    ]
  },
  schema: {
    groups: [{
      name: 'Galeria',
      fields: [
        {
          type: 'text-field',
          label: 'Título',
          model: 'title',
          required: true
        },
        {
          type: 'text-field',
          label: 'Chave',
          model: 'key',
          required: true
        },
        {
          type: 'multiple-files',
          label: 'Imagens',
          model: 'items'
        }
      ]
    }]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/galleries',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'galleries',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: "galleries" */ '../../../views/Crud.vue')
  }, {
    path: '/galleries/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'galleries',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: "galleries" */ '../../../views/Crud.vue')
  }, {
    path: '/galleries/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'galleries',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: "galleries" */ '../../../views/Crud.vue')
  }
]
