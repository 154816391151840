<template>
  <div class="m-array__container" :class="'m-array_for_' + fieldData.model">
    <div class="m-array__header">
      <h2 class="m-array__label">{{ fieldData.label }}</h2>
      <v-btn
        @click="addLine"
        class="primary m-array__add-button"
      >
        <v-icon>add</v-icon> adicionar
      </v-btn>
    </div>

    <draggable-stuff
      :list="tempValue"
      group="people"
      v-bind="dragOptions"
      @start="drag=true"
      @end="endDrag"
      class="field-array__list"
      v-if="tempValue"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <div
          v-for="(item, idx) in tempValue"
          :key="'aa' + idx"
          :class="{ '-draggable': isDraggable }"
        >
          <m-array-line
            :fields="fieldData.arrayOf.fields"
            :value="tempValue[idx]"
            @input="updateModel"
            :index="idx"
            :can-add-subitem="true"
            @add="addLine(idx)"
            @delete="removeItem(idx)"
          />
          <div
            v-for="(subitem, idz) in tempValue[idx].children"
            :key="'arraySubItem' + idz"
            class="m-array__subitem-container"
          >
            <v-icon class="m-array__subitem-icon">shortcut</v-icon>
            <m-array-line
              class="m-array__subitem"
              :fields="fieldData.arrayOf.fields"
              :value="tempValue[idx].children[idz]"
              @input="updateModel"
              :index="idz"
              @add="addLine(idx)"
              @delete="removeItem(idx)"
            />
          </div>
        </div>
      </transition-group>
    </draggable-stuff>

    <p v-else class="m-array__empty">
      Ainda não há ítens cadastrados.
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import draggableStuff from 'vuedraggable'
import mArrayLine from './m-array-line'

export default {
  components: {
    'm-array-line': mArrayLine,
    'draggable-stuff': draggableStuff
  },
  data () {
    return {
      drag: false,
      tempValue: []
    }
  },
  computed: {
    ...mapGetters({
      selectedItem: 'crud/selectedItem'
    }),

    model () {
      const obj = {}

      this.fieldData.arrayOf.fields.forEach((item) => {
        obj[item.model] = item.model === 'position' ? this.tempValue.length : ''
      })

      return obj
    },

    isDraggable () {
      return true
    },

    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  props: ['fieldData'],
  methods: {
    endDrag (evt) {
      this.resetPositions()
    },

    addLine (parentIdx = undefined) {
      if (parentIdx >= 0) {
        if (!('children' in this.tempValue[parentIdx])) {
          this.tempValue[parentIdx].children = []
        }

        this.tempValue[parentIdx].children.push(this.model)

        return
      }

      const model = this.model

      this.tempValue.push(model)
    },

    removeItem (index) {
      // this.tempValue.splice(index, 1)
      // console.log(this.tempValue[index])
      // this.resetPositions()
    },

    translate (item) {
      const translations = this.fieldData.translations || {
        title: 'Nome',
        description: 'Valor',
        position: 'Posição'
      }
      return translations[item]
    },

    updateModel (field) {
      this.$emit('input', { field: this.fieldData.model, value: this.tempValue })
    },

    resetPositions () {
      this.tempValue.forEach((item, idx) => { item.position = idx })
    },

    setOriginalState () {
      this.tempValue = this.selectedItem[this.fieldData.model]

      if (Array.isArray(this.tempValue)) {
        this.tempValue.sort((a, b) => a.position - b.position)
        this.resetPositions()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.tempValue = []
      if (this.selectedItem && this.selectedItem[this.fieldData.model]) {
        this.setOriginalState()
        // console.log('marray', this.selectedItem)
        // console.log('this.tempValue', this.tempValue)
      }
    })
  }
}
</script>
<style lang="scss">
  .m-array {
    &__subitem {
      padding: 0 20px 10px 70px;
      margin-top: -3px;

      &-container {
        position: relative;
      }

      &-icon.v-icon {
        position: absolute;
        top: 10px;
        left: 50px;
        font-size: 30px;
        z-index: 10;
        opacity: .4;
        transform: scaleY(-1);

      }

      .m-array-line__index,
      .m-array-line__drag-icon {
        display: none;
      }

    }

    &__container {
      position: relative;
      width: 100%;

      ul {
        margin-top: 30px;
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0;
        list-style: none;

        li {
          margin-bottom: 20px;
          display: flex;
          flex-flow: wrap;
          background: #e8e8e8;
          padding: 30px 20px;
          border-radius: 6px;
        }
      }

      .ck.ck-editor .ck-editor__editable {
        min-height: 0;
      }
    }

    &__header {
      display: flex;
    }

    &__label {
      padding-bottom: 20px;
    }

    &__add-button {
      position: absolute;
      right: 0;
      top: 0;
      padding: 5px !important;
      height: auto;

      .v-icon {
        font-size: 17px;
        opacity: 1;
      }

      .v-btn__content {
        font-size: 10px;
        font-weight: 700;
      }
    }

    &__delete-button {
      margin: auto 0 auto auto;
    }

    &__empty {
      padding: 40px;
      font-size: 15px;
      color: #999;
      background-color: #ddd;
      text-align: center;
      margin-top: 10px;
    }
  }
</style>
