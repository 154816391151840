const entity = {
  name: {
    singular: 'Módulo',
    plural: 'Módulos'
  },
  collection: 'modules',
  tableHeaders: [
    { text: 'Nome', value: 'name' },
    { text: 'Sistema', value: 'system' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    name: '',
    code: '',
    icon: '',
    route: '',
    system: '',
    fields: [],
    permissions: []
  },
  config: {
    name: 'Config',
    fields: [
      {
        type: 'select',
        label: 'Sistema',
        model: 'system',
        multiple: true,
        items: [
          { title: 'Base', _id: 'base' },
          { title: 'CMS', _id: 'cms' },
          { title: 'ERP', _id: 'erp' },
          { title: 'School', _id: 'sch' },
          { title: 'Profiler', _id: 'prf' },
          { title: 'LoMS', _id: 'loms' }
        ]
      },
      {
        type: 'text-field',
        label: 'Name',
        model: 'name',
        required: true
      },
      {
        type: 'text-field',
        label: 'Code',
        model: 'code',
        required: true
      },
      {
        type: 'text-field',
        label: 'Icon',
        model: 'icon',
        required: true
      },
      {
        type: 'text-field',
        label: 'Route',
        model: 'route',
        required: true
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Fields',
        fields: [
          {
            type: 'array',
            label: 'Fields',
            model: 'fields',
            arrayOf: {
              fields: [{
                type: 'select',
                label: 'Role',
                model: 'role',
                items: [
                  { title: 'Administrador', _id: 'admin' },
                  { title: 'Supervisor', _id: 'supervisor' },
                  { title: 'Redator', _id: 'writer' }
                ]
              }, {
                type: 'select',
                label: 'Action',
                model: 'action',
                multiple: true,
                items: [
                  { title: 'Create', _id: 'create' },
                  { title: 'Read', _id: 'read' },
                  { title: 'Update', _id: 'update' },
                  { title: 'Delete', _id: 'delete' }
                ]
              }, {
                type: 'text-field',
                inputType: 'text',
                label: 'Posição',
                model: 'position',
                required: true
              }]
            }
          }
        ]
      },
      {
        name: 'Permissões',
        fields: [
          {
            type: 'array',
            label: 'Permissions',
            model: 'permissions',
            required: true,
            arrayOf: {
              fields: [{
                type: 'select',
                label: 'Role',
                model: 'role',
                items: [
                  { title: 'Administrador', _id: 'admin' },
                  { title: 'Supervisor', _id: 'supervisor' },
                  { title: 'Redator', _id: 'writer' }
                ]
              }, {
                type: 'select',
                label: 'Action',
                model: 'action',
                multiple: true,
                items: [
                  { title: 'Create', _id: 'create' },
                  { title: 'Read', _id: 'read' },
                  { title: 'Update', _id: 'update' },
                  { title: 'Delete', _id: 'delete' }
                ]
              }, {
                type: 'text-field',
                inputType: 'text',
                label: 'Posição',
                model: 'position',
                required: true
              }]
            }
          }
        ]
      },
      {
        name: 'Formulário',
        fields: [{
          type: 'form-generator',
          label: 'Schema',
          model: 'form',
          required: false
        }]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/modules',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'modules',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/modules/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'modules',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/modules/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'modules',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }
]
