<template>
  <v-textarea
    :ref="fieldData.model"
    v-model="proxyValue"
    :label="fieldData.label"
    :required="fieldData.required"
    auto-grow
    rows="1"
    :hint="fieldData.hint"
  />
</template>
<script>
import mMixin from './mixin'

export default {
  name: 'm-textarea',
  mixins: [mMixin]
}
</script>
