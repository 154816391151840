const entity = {
  name: {
    singular: 'Carrier',
    plural: 'Carriers'
  },
  collection: 'carriers',
  tableHeaders: [
    { text: 'Name', value: 'name' },
    { text: 'Phone', value: 'landline' },
    { text: 'Email', value: 'email' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    mcNumber: '',
    dotNumber: '',
    notes: '',
    name: '',
    email: '',
    landline: '',
    cellphone: '',
    address: '',
    address_two: '',
    neighborhood: '',
    city: '',
    state: '',
    zipcode: '',
    loads: [],
    trucks: [],
    drivers: [],
    loadBoards: []
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      { label: 'MC Number', model: 'mcNumber', size: 'lg12' },
      { label: 'DOT Number', model: 'dotNumber', size: 'lg12' },
      { label: 'Notes', model: 'notes', size: 'lg12', type: 'textarea' }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Details',
        fields: [
          { type: 'text-field', label: 'Name', model: 'name', size: 'lg12' },
          { type: 'text-field', label: 'Email', model: 'email', size: 'lg6' },
          { type: 'text-field', label: 'Phone', model: 'landline', size: 'lg3' },
          { type: 'text-field', label: 'Cellphone', model: 'cellphone', size: 'lg3' },
          { type: 'text-field', label: 'Address', model: 'address', size: 'lg8' },
          { type: 'text-field', label: 'Address2', model: 'address_two', size: 'lg3' },
          { type: 'text-field', label: 'Neighborhood', model: 'neighborhood', size: 'lg3' },
          { type: 'text-field', label: 'City', model: 'city', size: 'lg5' },
          { type: 'text-field', label: 'State', model: 'state', size: 'lg1' },
          { type: 'text-field', label: 'Zip', model: 'zipcode', size: 'lg3' }
        ]
      },
      {
        name: 'Drivers',
        groupType: 'list',
        entity: 'drivers',
        itemsPerPage: 50,
        fields: ['name', 'mobilePhone'],
        tableHeaders: [
          { text: 'Name', value: 'name' },
          { text: 'Mobile Phone', value: 'mobilePhone' },
          { text: 'Actions', value: 'action', sortable: false }
        ],
        relatedCollection: 'carriers',
        relatedCollectionIdField: 'carrier',
        editOnDrawer: true
      },
      {
        name: 'Trucks',
        fields: [
          {
            label: 'Trucks',
            type: 'array',
            model: 'trucks',
            arrayOf: {
              fields: [
                { label: 'Make', model: 'make', size: 'lg2' },
                { label: 'Model', model: 'model', size: 'lg2' },
                { label: 'Type', model: 'type', size: 'lg2' },
                { label: 'Capacity', model: 'capacity', size: 'lg2' },
                { label: 'Position', model: 'position', required: true }
              ]
            }
          }
        ]
      },
      {
        name: 'LoadBoards',
        fields: [
          {
            label: 'Load Boards',
            type: 'array',
            model: 'loadBoards',
            arrayOf: {
              fields: [
                { label: 'LoadBoard', model: 'loadBoard', size: 'lg4' },
                { label: 'Username', model: 'username', size: 'lg3' },
                { label: 'Password', model: 'password', size: 'lg3' },
                { label: 'Position', model: 'position', required: true }
              ]
            }
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/carriers',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'carriers',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/carriers/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'carriers',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/carriers/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'carriers',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }
]
