import FileUpload from 'vue-upload-component'
import uploadService from '../../services/UploadService'

export default {
  components: {
    FileUpload
  },
  data () {
    return {
      files: [],
      uploading: false,
      refName: ''
    }
  },
  props: ['fieldData', 'value'],
  mounted () {
    this.$nextTick(() => {
      if (this.value && this.value.path) {
        this.files = Array.from([this.value])
      }

      this.refName = 'upload' + this.fieldData.model
    })
  },
  computed: {
    entity () {
      return this.$route.meta.entity
    },
    multiple () {
      return false
    }
  },
  methods: {
    inputFile (newFile, auto = false) {
      this.$nextTick(() => {
        newFile.blob = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) newFile.blob = URL.createObjectURL(newFile.file)

        newFile.url = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') newFile.url = newFile.blob

        const newFiles = [newFile]
        this.files = Array.from(newFiles)

        if (auto) this.upload()
      })
    },
    inputFileAuto (newFile) {
      this.inputFile(newFile, true)
    },
    async upload () {
      this.uploading = true
      const collection = this.entity.collection

      try {
        const response = await uploadService(collection).uploadSingleFile(this.files[0])
        if (response) {
          const newFiles = [response]
          this.files = Array.from(newFiles)
        }

        this.$emit('input', { field: this.fieldData.model, value: this.files[0] })
        this.uploading = false
      } catch (error) {
        this.uploading = false
      }
    },
    async removeItem (file) {
      this.files = []
      this.$emit('input', { field: this.fieldData.model, value: [] })
      if (!file.uploaded) return

      const collection = this.entity.collection
      let filePath = file.path

      if (filePath.startsWith('https')) {
        filePath = filePath.replace('https://firebasestorage.googleapis.com/v0/b/maestro-143618.appspot.com/o', '')
        filePath = filePath.includes('?') ? filePath.split('?').shift() : filePath
        filePath = decodeURIComponent(filePath)
        // console.log(filePath)
      }

      if (file.uploaded) {
        try {
          await uploadService(collection).deleteFile(filePath)
        } catch (error) {
          // console.log(error)
          this.files = []
        }
      }
    }
  }
}
