export default [
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../../../views/Auth.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../../../views/Auth.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../../../views/Auth.vue')
  },
  {
    path: '/password-reset/:token',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "register" */ '../../../views/Auth.vue')
  },
  {
    path: '/confirm-account/:token',
    name: 'confirm-account',
    component: () => import(/* webpackChunkName: "register" */ '../../../views/Auth.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "register" */ '../../../views/Auth.vue')
  },
  {
    path: '/cms',
    name: 'account-set',
    component: () => import(/* webpackChunkName: "register" */ '../../../views/Auth.vue')
  },
  {
    path: '/cms/:accountId',
    name: 'account-set',
    component: () => import(/* webpackChunkName: "register" */ '../../../views/Auth.vue')
  }
]
