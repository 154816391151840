<template>
  <v-tabs
    background-color="transparent"
    @change="selectTab"
    v-model="activeSliderIndex"
  >
    <v-tab
      v-for="(tab, idx) in tabs"
      :key="idx"
    >
      {{ tab.name }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'm-toolbar',
  data () {
    return {
      activeSliderIndex: 0
    }
  },
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: null
    }
  },
  watch: {
    $route () {
      this.selectTab(0)
      this.activeSliderIndex = 0
    }
  },
  methods: {
    selectTab (id) {
      this.$emit('click', id)
    }
  }
}
</script>

<style lang="scss">
.m-toolbar__container {
  @media screen and (max-width: 768px) {
    top: -24px;
    position: relative;
    padding: 20px;
    max-width: calc(100% - 20px);
  }

  .v-toolbar__title {

  }

  .v-sheet .v-toolbar__content {
  }

  .m-toolbar__title-bar {

  }
}
</style>
