import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import authenticationStore from './AuthenticationStore'
import crudStore from './CrudStore'
import MessagesStore from './MessagesStore'

Vue.use(Vuex)

const mainStore = new Vuex.Store({
  modules: {
    authentication: authenticationStore,
    crud: crudStore,
    messages: MessagesStore
  }
})

export default mainStore
