const entity = {
  name: {
    singular: 'Banner',
    plural: 'Banners'
  },
  styles: {
    gridTemplateColumns: '20% 1fr'
  },
  collection: 'banners',
  tableHeaders: [
    { text: 'Nome', value: 'title' },
    { text: 'Código', value: 'slug' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    title: '',
    items: [],
    slug: '',
    published: false,
    date_in: null,
    date_out: null
  },
  config: {
    name: 'Config',
    fields: [
      {
        type: 'text-field',
        label: 'Url amigável',
        model: 'slug',
        required: true
      },
      {
        type: 'date-picker',
        label: 'Data de Entrada',
        model: 'date_in',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'date-picker',
        label: 'Data de Saída',
        model: 'date_out',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado'
      }
    ]
  },
  schema: {
    fields: [
      {
        type: 'text-field',
        label: 'Nome',
        model: 'title',
        required: true
      },
      {
        type: 'gallery-cards',
        label: 'Imagens',
        model: 'items',
        required: true
      }
    ]
  }
}

export default [
  {
    path: '/banners',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'banners',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/banners/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'banners',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/banners/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'banners',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }
]
