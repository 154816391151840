<template>
  <v-radio-group v-model="proxyValue">
    <template v-slot:label>
      <div>{{ fieldData.label }}</div>
    </template>
    <v-radio
      v-for="item in fieldData.items"
      :label="item.title"
      :key="item._id"
      :value="item._id"
    />
  </v-radio-group>
</template>
<script>
import mMixin from '@/components/mComponents/mixin'

export default {
  name: 'm-radio',
  mixins: [mMixin]
}
</script>
<style lang="scss" scoped>
.v-input--radio-group {
  &--row {
    legend: 100%;
  }
}
</style>
