<template>
  <v-layout class="m-table">
    <v-flex>
      <v-card-title>
        <h2
          class="headline"
          primary-title
        >{{ fieldData.name }}</h2>
        <v-spacer />
        <v-text-field
          v-if="loaded && items.length"
          v-model="query"
          append-icon="search"
          label="Procurar"
          single-line
          hide-details
        />
        <v-btn class="primary"  @click="$emit('add')" v-if="fieldData.addButton">
          <v-icon>add</v-icon>
          Incluir {{ fieldData.name }}
        </v-btn>
      </v-card-title>

      <v-data-table
         v-if="loaded && items.length"
        :headers="headers"
        :items="items"
        :items-per-page="fieldData.itemsPerPage"
        :search="query"
        class="m-table__container"
        @click:row="openItem"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item._id">
              <td
                v-for="(column, idx) in bodyColumns"
                :key="'column-' + item._id + '-' + idx"
                :id="'column-' + item._id + '-' + idx"
                @click="openItem(item)"
                :class="`m-table__column-type--${column.type || 'simple'}`"
                :inner-html.prop="formatValue(item, column)"
              />
              <td>
                <v-icon small class="mr-2" @click="openItem(item)">edit</v-icon>
                <v-icon @click.prevent="removeItem(file)">delete</v-icon>
              </td>
            </tr>
          </tbody>
        </template>

        <!-- <template v-slot:item.action="props">
          <v-icon small class="mr-2" @click="openItem(item)">edit</v-icon>
          <v-icon small @click="itemDelete(props.item)" class="mr-2">delete</v-icon>
        </template> -->
      </v-data-table>
    </v-flex>
  </v-layout>
</template>
<script>
import mMixin from './mixin'
import crudService from '@/services/CrudService'

export default {
  name: 'm-table',
  mixins: [mMixin],
  data () {
    return {
      loaded: false,
      items: [],
      headers: [],
      query: null,
      entity: null,
      selectedItems: []
    }
  },
  computed: {
    bodyColumns () {
      if (!this.tableHeaders) return []

      const temp = Array.from(this.tableHeaders)
      temp.pop()
      return temp
    },
    tableHeaders () {
      return this.fieldData.tableHeaders || this.fieldData.fields
    }
  },
  async mounted () {
    // if (Array.isArray(this.fieldData.items)) {
    //   this.items = [...this.fieldData.items]
    //   return
    // }

    await this.setInitialState()
  },
  methods: {
    setInitialState: async function () {
      const parentEntityId = this.$route.params.id
      const { entity, relatedCollection, fields } = this.fieldData
      this.entity = entity

      const results = await crudService(entity).getRelatedContent(relatedCollection, parentEntityId, fields)
      this.headers = fields.map(field => { return { text: field, value: field } })
      this.loaded = true

      let relatedEntity = this.$router.options.routes.find((item) => item.name === entity)
      if (relatedEntity) this.entity = relatedEntity.meta.entity

      if (results.empty) return
      this.items = Array.from(results.data)
    },
    openItem: function (item) {
      if (this.fieldData.editOnDrawer) {
        this.$emit('select', item)
        return
      }

      this.$router.push({
        path: `/${this.entity}/${item._id}`,
        query: {
          backTo: this.$route.path
        }
      })

      // window.open(`/#/${this.entity}/${item._id}`)
    },
    itemDelete: function (item) {
      if (confirm('Você deseja mesmo deletar este item?')) {
        this.deleteItem({ currentUserId: this.currentUser.id, collection: this.entity.collection, item })
      }
    },
    formatValue (item, column) {
      // console.log('item', item)
      // console.log('column', column)

      const value = column && column.value && column.value.includes('.')
        ? item[column.value.split('.')[0]][column.value.split('.')[1]]
        : item[column.value]

      if (column.type === 'datetime') {
        return (new Date(item[column.value])).toLocaleString('pt-BR')
      }

      if (column.type === 'datetime') {
        return (new Date(item[column.value])).toLocaleString('pt-BR')
      }

      if (column.type === 'date') {
        return (new Date(item[column.value])).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' })
      }

      if (column.type === 'avatar') {
        const dummyImage = 'gender' in item && item.gender === 'male' ? 'img/avatar/boy.jpeg' : 'img/avatar/girl.jpeg'
        const fileUrl = value && value.url ? value.url : dummyImage

        return `<img src="${fileUrl}" class="m-table__list-avatar" >`
      }

      if (column.type === 'arrayOfParents') {
        const field = column.value
        const values = item[field]

        if (values.length && Array.isArray(values) && column.parents) {
          const mappedValues = values.map((id) => {
            const parent = column.parents.find((item) => item._id === id)
            if (parent) {
              return parent.title
            }
            return null
          })
          item[field] = mappedValues.join(', ')
        }
      }

      return value
    }
  }
}
</script>

<style lang="scss">
  .m-table__container {
    width: 100%;

    td {
      cursor: pointer;
    }
  }

  .m-table {
    position: relative;

    .m-table {
      &__modal {

        &-trigger {
          position: absolute;
          right: 0;
          top: -10px;
        }
      }
    }

    .m-table__column-type--avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      padding: 5px;

      img {
        padding: 5px;
        width: 50px;
        height: 50px;
        margin: 5px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
</style>
