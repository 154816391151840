import crudService from '../services/CrudService'

const crudStore = {
  namespaced: true,
  state: {
    list: [],
    selectedItem: null,
    selectedModule: null
  },
  mutations: {
    setList: (state, list) => {
      state.list = Array.from(list)
    },
    addItem: (state, item) => {
      state.list.unshift(item)
    },
    delete: (state, id) => {
      state.list = state.list.filter(item => item.id !== id)
    },
    selectItem: (state, item) => {
      state.selectedItem = item
    },
    selectModule: (state, module) => {
      state.selectedModule = module
    },
    updateStateSection: (state, newData) => {
      state.list.map(item => {
        if (item.id === newData.id) Object.assign(item.data, item.data)
      })
    },
    updateSelectedItemProperty: (state, data) => {
      if (data.field.includes('.')) {
        const [ parent, child ] = data.field.split('.')
        state.selectedItem[parent][child] = data.value
        return
      }

      state.selectedItem[data.field] = data.value
    }
  },
  actions: {
    async getItemById ({ commit }, data) {
      const item = await crudService(data.collection, data.id).getItemById()
      if (item.status === 200 && item.data) {
        item.data.id = item.data._id
        commit('selectItem', item.data)
        return item.data
      }
      return false
    },
    async getList ({ commit }, data) {
      const fields = data.fields ? data.fields : null
      try {
        const list = await crudService(data.collection).getList(fields)
        if (!list) return

        commit('setList', [])

        const listFormatted = list.data.map((item) => {
          item.id = item._id
          return item
        })

        commit('setList', listFormatted)
      } catch (error) {
        // console.error(error)
      }
    },

    addToList: ({ commit }, item) => {
      commit('addItem', item)
    },

    getItem: ({ commit }, item) => {
      commit('selectItem', item)
    },

    deleteItem: async ({ commit }, data) => {
      const response = await crudService(data.collection, data.item).deleteItem()
      commit('delete', data.item.id)
      return response
    },

    selectItem: async ({ dispatch, commit }, item) => {
      commit('selectItem', item)
    },

    createItem: async ({ dispatch }, data) => {
      const response = await crudService(data.collection, data.item).createItem()
      if (response) dispatch('addToList', response)
      return response
    },

    updateItem: async ({ dispatch, commit }, data) => {
      // console.log(data)
      const response = await crudService(data.collection, data.item).updateItem()
      return response
    },

    clearSelected: ({ commit }) => {
      commit('selectItem', null)
    },

    updateProperty: async ({ commit }, data) => {
      commit('updateSelectedItemProperty', data)
    }
  },
  getters: {
    selectedModule: state => state.selectedModule,
    selectedItem: state => state.selectedItem,
    listItems: state => state.list
  }
}

export default crudStore
