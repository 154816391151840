// import { firebaseStorage } from '../plugins/firebase'
import { localPersistence } from '@/services/LocalPersistenceService'
// import slugify from 'slugify'
import axios from 'axios'

const crudService = (collection, data) => {
  const token = localPersistence.get('mstrToken')

  axios.defaults.baseURL = process.env.VUE_APP_ENV_API
  axios.defaults.headers['Authorization'] = token

  const getList = async (fields) => {
    try {
      const queryString = fields ? '?fullTitle=true&fields=' + fields : '?fullTitle=true'
      const response = await axios(collection + queryString)
      return response
    } catch (error) {
      throw new Error(error)
    }
  }

  const getRelatedContent = async (foreignKey, foreignId, fields) => {
    const url = `/${foreignKey}/${foreignId}/${collection}/?fields=${fields}`
    const response = await axios(url)
    return response
  }

  const createItem = async () => {
    try {
      const response = await axios.post(collection, data)

      if (response.data.status !== 'error') {
        const savedData = await response.data

        let newObj = {
          id: response.id,
          ...savedData
        }
        return newObj
      }

      return response.data
    } catch (error) {
      return error
    }
  }

  const getItemById = async () => {
    const itemId = data
    const item = await axios(collection + '/' + itemId)
    return item
  }

  const deleteItem = async () => {
    const itemId = data.id
    const response = await axios.delete(collection + '/' + itemId)
    return response
  }

  const updateItem = async () => {
    const itemId = data.id || data._id
    // Upload related
    // data = await handleFileFields(data)

    // console.log(data, collection)

    const response = await axios.patch(collection + '/' + itemId, data)
    return response
  }

  // const uploadFile = async (file, field) => {
  //   if (!file) return

  //   const dir = `/Maestro/${accountId}/${collection}/`
  //   const ref = firebaseStorage.ref().child(dir)

  //   file = file.split(',')[1]

  //   let entityName = data.name || data.title || collection
  //   entityName = slugify(entityName, { lower: true })

  //   const fileName = `${(+new Date())}-${entityName}-${field}.jpg`
  //   const task = ref.child(fileName).putString(file, 'base64')
  //   const response = await task

  //   const url = response.ref.getDownloadURL()

  //   return url
  // }

  const importPDF = async (url) => {
    await axios('/importers/pdf?url=' + url)
  }

  const importFile = async (url) => {
    await axios('/importers/all?url=' + url)
  }

  return {
    getList,
    createItem,
    getItemById,
    updateItem,
    deleteItem,
    getRelatedContent,
    importPDF,
    importFile
  }
}

export default crudService
