import FileUpload from 'vue-upload-component'
import uploadService from '@/services/UploadServiceFirebase'

export default {
  components: {
    FileUpload
  },
  data () {
    return {
      files: [],
      accept: 'image/png,image/gif,image/jpeg,image/webp',
      extensions: 'gif,jpg,jpeg,png,webp',
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      thread: 3,
      name: 'file',
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      data: {
      },
      addData: {
        show: false,
        name: '',
        type: '',
        content: ''
      },
      editFile: {
        show: false,
        name: ''
      },
      uploading: false
    }
  },
  props: ['fieldData', 'value'],
  mounted () {
    if (this.value && this.value.length) {
      this.files = Array.from(this.value)
    }
  },
  computed: {
    refName () {
      return 'm-type-' + this.fieldData.model
    },
    entity () {
      return this.$route.meta.entity
    },
    disableUploadButton () {
      if (this.uploading) return true
      const pendingUploadFiles = this.files.filter(item => !item.uploaded)
      return pendingUploadFiles.length === 0
    }
  },
  methods: {
    inputFile (newFile, oldFile) {
      // console.log('uploadMixinMultiple.js', newFile)
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }

      newFile.url = ''
      if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
        newFile.url = newFile.blob
      }

      this.files.push(newFile)

      // console.log(this.files)
    },
    async upload () {
      this.uploading = true
      const collection = this.entity.collection

      try {
        const response = await uploadService(collection).uploadFiles(this.files)
        if (response) {
          // console.log('Response from Service', response)
          this.files = Array.from(response)
        }
        this.$emit('input', { field: this.fieldData.model, value: this.files })
        this.uploading = false
      } catch (error) {
        // console.log(error)
        this.uploading = false
      }
    },
    updateModel () {
      this.$emit('input', { field: this.fieldData.model, value: this.files })
    },
    async removeItem (file) {
      this.files = Array.from(this.files.filter(item => item.id !== file.id))
      this.updateModel()
      if (!file.uploaded) return

      const collection = this.entity.collection
      let filePath = file.path

      if (filePath.startsWith('https')) {
        filePath = filePath.replace('https://firebasestorage.googleapis.com/v0/b/maestro-143618.appspot.com/o', '')
        filePath = filePath.includes('?') ? filePath.split('?').shift() : filePath
        filePath = decodeURIComponent(filePath)
        // console.log(filePath)
      }

      try {
        await uploadService(collection).deleteFile(filePath)
      } catch (error) {
        // console.log(error)
      }
    }
  }
}
