const entity = {
  name: {
    singular: 'Seção',
    plural: 'Seções'
  },
  collection: 'sections',
  tableHeaders: [
    { text: 'Seção', value: 'title' },
    { text: 'Tipo', value: 'type' },
    { text: 'Url Amigável', value: 'slug' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    parent: null,
    slug: null,
    title: null,
    type: 'single',
    summary: null,
    position: '',
    sidebar: false,
    fileMainImage: [],
    fileSecondaryImage: [],
    date_in: null,
    date_out: null,
    pudlished: true,
    clickableContent: true,
    searchableContent: true
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      {
        type: 'select',
        label: 'Subseção de',
        model: 'parent',
        multiple: true,
        collection: 'sections',
        size: 'xs12 sm12 md12 lg12'
      },
      {
        type: 'pills',
        label: 'Subseção de',
        model: 'parent',
        multiple: true,
        collection: 'sections',
        size: 'xs12 sm12 md12 lg12'
      },
      {
        type: 'date-picker',
        label: 'Data de Entrada',
        hint: 'Deixe em branco caso não tenha data limite.',
        model: 'dateIn',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'date-picker',
        label: 'Data de Saída',
        hint: 'Deixe em branco caso não tenha data limite.',
        model: 'dateOut',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'radio',
        model: 'type',
        label: 'Tipo de seção',
        items: [
          { title: 'Único', _id: 'single' },
          { title: 'Lista simples', _id: 'list' },
          { title: 'Grid Imagens', _id: 'imageList' },
          { title: 'Cards', _id: 'cardList' }
        ],
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'radio',
        model: 'sidebar',
        label: 'Sidebar',
        items: [
          { title: 'Nenhuma', _id: 'none' },
          { title: 'Esquerda', _id: 'left' },
          { title: 'Direita', _id: 'right' }
        ],
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'switch',
        model: 'clickableContent',
        label: 'Conteúdo Clicável'
      },
      {
        type: 'switch',
        model: 'searchableContent',
        label: 'Exibir o conteúdo na busca'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Detalhes',
        fields: [
          {
            type: 'text-field',
            label: 'Título',
            model: 'title',
            required: true,
            size: 'xs12 sm12 md6 lg6'
          },
          {
            type: 'text-field',
            label: 'Url amigável',
            model: 'slug',
            required: true,
            size: 'xs12 sm12 md6 lg6'
          },
          {
            type: 'text-field',
            label: 'Resumo',
            model: 'summary',
            required: false,
            size: 'xs12 sm12 md12 lg12'
          },
          {
            type: 'single-file',
            label: 'Imagem grande',
            model: 'fileMainImage',
            multiple: false,
            required: false,
            hideInput: true,
            size: 'xs12 sm12 md6 lg6'
          },
          {
            type: 'single-file',
            label: 'Imagem pequena',
            model: 'fileSecondaryImage',
            multiple: false,
            required: false,
            hideInput: true,
            size: 'xs12 sm12 md6 lg6'
          }
        ]
      },
      {
        name: 'Sub-seções',
        groupType: 'list',
        entity: 'sections',
        itemsPerPage: 50,
        fields: ['title', 'slug'],
        tableHeaders: [
          { text: 'Seção', value: 'title' },
          { text: 'Url Amigável', value: 'slug' },
          { text: 'Actions', value: 'action', sortable: false }
        ],
        relatedCollection: 'sections',
        relatedCollectionIdField: 'parent',
        editOnDrawer: false
      },
      {
        name: 'Conteúdo',
        groupType: 'list',
        entity: 'contents',
        itemsPerPage: 50,
        fields: ['title', 'slug'],
        tableHeaders: [
          { text: 'Título', value: 'title' },
          { text: 'Url Amigável', value: 'slug' },
          { text: 'Actions', value: 'action', sortable: false }
        ],
        relatedCollection: 'sections',
        relatedCollectionIdField: 'sections',
        editOnDrawer: false
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/sections',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'sections',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: "sections" */ '../../../views/Crud.vue')
  }, {
    path: '/sections/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'sections',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: "sections" */ '../../../views/Crud.vue')
  }, {
    path: '/sections/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'sections',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: "sections" */ '../../../views/Crud.vue')
  }
]
