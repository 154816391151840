<template>
<div class="m-gallery__container">
  <h3 class="mb-4">{{ fieldData.label }}</h3>

  <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
    <h3>Arraste os arquivos aqui para enviar</h3>
  </div>

  <div class="upload">
    <div class="table-responsive">
      <div v-if="!files || !files.length" class="drop-zone">
        <h4>Arraste os arquivos aqui</h4>
      </div>

      <v-layout row wrap
          class="m-gallery__list">
        <v-flex
          v-for="(file, idx) in files"
          :key="idx"
          class="m-gallery__list-item"
        >
          <v-card>
            <v-img
              :src="file.url"
              @click="openPhoto(file.uploaded ? file.url : file.thumb)"
            />

            <v-card-text v-if="!file.uploaded">
              <span v-if="file.name">{{ file.name }}</span><br>
              <span v-if="file.size">{{ `${Math.round(file.size/1024)}Kb` }}</span>
            </v-card-text>

            <v-card-text v-else>
              <v-text-field
                v-model="file.title"
                label="Título"
              />
              <v-text-field
                v-model="file.subtitle"
                label="Subtítulo"
              />
              <v-text-field
                v-model="file.body"
                label="Corpo"
              />
              <v-text-field
                v-model="file.link"
                label="Link"
              />
              <v-text-field
                v-model="file.position"
                label="Posição"
              />
            </v-card-text>

            <v-card-actions>
              <v-chip v-if="file.uploaded" color="teal" text-color="white">
                <v-avatar>
                  <v-icon>check_circle</v-icon>
                </v-avatar>
                UPLOADED
              </v-chip>
              <v-spacer />
              <v-icon @click.prevent="removeItem(file)">delete</v-icon>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </div>

    <div class="example-foorer">
      <div class="btn-group">
        <file-upload
          class="v-btn v-btn--contained theme--dark v-size--default success bt-selecionar-images"
          :extensions="extensions"
          :accept="accept"
          multiple
          :size="size || 0"
          :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
          :data="data"
          :drop="drop"
          :drop-directory="dropDirectory"
          :add-index="addIndex"
          @input-file="inputFile"
          :ref="refName"
          :input-id="refName"
        >
          Selecionar arquivos
        </file-upload>

        <v-btn
          @click="upload()"
          class="theme--light default bt-selecionar-images"
          :disabled="disableUploadButton"
        >
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
          Upload
        </v-btn>
      </div>
    </div>
  </div>
</div>

</template>
<style lang="scss">
  .m-gallery__container {
    width: 100%;

    .btn-group {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      font-weight: normal;
    }

    .btn-group .dropdown-menu {
      display: block;
      visibility: hidden;
      transition: all .2s;
    }

    .btn-group:hover > .dropdown-menu {
      visibility: visible;
    }

    label.dropdown-item {
      margin-bottom: 0;
    }

    .btn-group .dropdown-toggle {
      margin-right: .6rem;
    }

    .filename {
      margin-bottom: .3rem;
    }

    .btn-is-option {
      margin-top: 0.25rem;
    }

    .example-foorer {
      padding: .5rem 0;
      border-top: 1px solid #e9ecef;
      border-bottom: 1px solid #e9ecef;
    }

    .edit-image img {
      max-width: 100%;
    }

    .edit-image-tool {
      margin-top: .6rem;
    }

    .edit-image-tool .btn-group {
      margin-right: .6rem;
    }

    .footer-status {
      padding-top: .4rem;
    }

    .drop-active {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: fixed;
      z-index: 9999;
      opacity: .6;
      text-align: center;
      background: #000;
    }

    .drop-active h3 {
      margin: -.5em 0 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      font-size: 40px;
      color: #fff;
      padding: 0;
    }
  }

  .bt-selecionar-images {
    display: inline-flex !important;
    align-items: center;
    width: auto;
    margin: 0 10px 0 0;
  }

  .drop-zone {
    border: 2px dotted #ddd;
    padding: 100px 30px;
    background-color: #f0f0f0;
    text-align: center;
    margin-top: 15px;
  }

  .theme--light.v-sheet {
    background-color: #ddd;
  }

  .v-image {
    height: 180px
  }

  .v-image__image {
    background-color: #e5e5e5;
    background-size: contain;
  }

  .m-gallery__list-item {
    margin: 10px;
    width: calc((100% / 3) - 20px);
    flex-grow: 0;
  }

</style>

<script>
import uploadMixinMultiple from './uploadMixinMultiple'

export default {
  name: 'mGalleryCards',
  mixins: [uploadMixinMultiple]
}
</script>
