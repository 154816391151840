<template>
  <div class="m-file__container">
  <h3>{{ fieldData.label }}</h3>
    <div
      v-show="$refs.upload && $refs.upload.dropActive"
      class="drop-active"
    >
      <h3>Arraste os arquivos aqui para enviar</h3>
    </div>

    <div class="upload" v-show="!isOption">
      <div class="table-responsive">
        <div
          v-if="showSelectFiles && !proxyValue.length"
          class="drop-zone"
        >
          <h4>Arraste os arquivos aqui</h4>
        </div>

        <!-- Multiple files -->
        <v-list text color="white" v-if="fieldData.multiple && proxyValue && proxyValue.length">
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(file, idx) in proxyValue"
              :key="idx"
            >
              <v-list-item-icon>
                <img
                  v-if="file.url"
                  :src="file.url"
                />
                <v-icon v-else :color="file.uploaded ? 'success' : ''">cloud_upload</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <a
                    :href="file.url"
                    target="_blank"
                    v-if="file && file.uploaded"
                  >{{ file.title || file.path }}</a>
                  <span v-else>{{ file.name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ `${Math.ceil(file.size/1024)}kB` }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon @click.prevent="removeItem(file)">delete</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <!-- Single file -->
        <v-list text color="white" v-if="!fieldData.multiple && proxyValue">
          <v-list-item-group color="primary">
            <v-list-item>
              <v-list-item-icon>
                <img
                  v-if="proxyValue.url"
                  :src="proxyValue.url"
                />
                <v-icon
                  v-else
                  :color="proxyValue.uploaded ? 'success' : ''"
                >cloud_upload</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <a
                    :href="proxyValue.url"
                    target="_blank"
                    v-if="proxyValue.uploaded"
                  >{{ proxyValue.title || proxyValue.path }}</a>
                  <span v-else>{{ proxyValue.url }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ `${Math.ceil(proxyValue.size/1024)}kB` }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon @click.prevent="removeItem(file)">delete</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>

      <div class="m-file__filezone btn-group" v-if="showSelectFiles">
        <file-upload
          v-if="fieldData.multiple || !proxyValue.length"
          :name="fieldData.model"
          class="v-btn v-btn--contained theme--light v-size--default primary bt-selecionar-images"
          :extensions="extensions"
          :accept="accept"
          :multiple="fieldData.multiple"
          :directory="directory"
          :size="size || 0"
          :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
          :drop="drop"
          :drop-directory="dropDirectory"
          :add-index="addIndex"
          :ref="'upload' + fieldData.model"
          @input-file="selectFile"
        >
          Selecionar arquivos
        </file-upload>

        <v-spacer />

        <v-btn
          @click="upload()"
          class="theme--light success"
          :disabled="disableUploadButton"
        >
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
          Upload
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import mMixin from './mixin'
import uploadService from '@/services/UploadService'
import FileUpload from 'vue-upload-component'

export default {
  name: 'mMultipleFiles',
  mixins: [mMixin],
  components: {
    FileUpload
  },
  data () {
    return {
      accept: '*',
      extensions: '*',
      minSize: 1024,
      size: 1024 * 1024 * 10,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      thread: 3,
      name: 'file',
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      multiple: false,
      addData: {
        show: false,
        name: '',
        type: '',
        content: ''
      },
      editFile: {
        show: false,
        name: ''
      },
      uploading: false,
      imageData: null
    }
  },
  props: {
    fieldData: Object
  },
  computed: {
    disableUploadButton () {
      if (this.uploading) return true
      return Boolean(this.proxyValue && Array.isArray(this.proxyValue) && this.proxyValue.filter(item => !item.uploaded).length === 0)
    },
    entity () {
      return this.$route.meta.entity
    },
    showSelectFiles () {
      return this.fieldData.multiple ? true : !this.proxyValue
    }
  },
  methods: {
    async upload () {
      this.uploading = true

      const collection = this.entity.collection

      try {
        const response = await uploadService(collection).uploadFiles(this.proxyValue)
        // console.log('ResponseFileUpload', response)
        if (response) {
          this.proxyValue = response
        }
      } catch (error) {
        // console.log(error)
        this.uploading = false
      }
    },
    async removeItem (file) {
      const collection = this.entity.collection
      let filePath = file.path

      if (filePath.startsWith('https')) {
        filePath = filePath.replace('https://firebasestorage.googleapis.com/v0/b/maestro-143618.appspot.com/o', '')
        filePath = filePath.includes('?') ? filePath.split('?').shift() : filePath
        filePath = decodeURIComponent(filePath)
        // console.log(filePath)
      }

      if (this.fieldData.multiple) {
        this.proxyValue = this.proxyValue.filter(item => item.id !== file.id)
      } else {
        this.proxyValue = null
      }

      if (file.uploaded) await uploadService(collection).deleteFile(filePath)
    },
    selectFile (newFile) {
      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }

      // Thumbnails
      newFile.url = ''
      if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
        newFile.url = newFile.blob
      }

      if (this.fieldata.multiple) {
        Vue.$set(this.proxyValue, this.proxyValue.length, newFile)
      } else {
        Vue.$set(this.proxyValue, newFile)
      }
    }
  },
  mounted () {
    if (this.fieldData.allowedFileTypes) this.accept = this.fieldData.allowedFileTypes
    if (this.fieldData.allowedExtensions) this.extensions = this.fieldData.allowedExtensions
  }
}
</script>
<style lang="scss">
  .m-file__container {
    width: 100%;

    .upload {
      margin-top: 15px;
    }

    .btn-group {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      font-weight: normal;
    }

    .btn-group .dropdown-menu {
      display: block;
      visibility: hidden;
      transition: all .2s;
    }

    .btn-group:hover > .dropdown-menu {
      visibility: visible;
    }

    label.dropdown-item {
      margin-bottom: 0;
    }

    .btn-group .dropdown-toggle {
      margin-right: .6rem;
    }

    .filename {
      margin-bottom: .3rem;
    }

    .btn-is-option {
      margin-top: 0.25rem;
    }

    .footer-status {
      padding-top: .4rem;
    }

    .drop-active {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: fixed;
      z-index: 9999;
      opacity: .6;
      text-align: center;
      background: #000;
    }

    .drop-active h3 {
      margin: -.5em 0 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      font-size: 40px;
      color: #fff;
      padding: 0;
    }

    @media screen and (max-width: 768px) {
      .btn-group {
        flex-direction: column;

        .v-btn {
          margin-bottom: 10px;
        }
      }

      .drop-zone {
        display: none;
      }
    }

    .v-list-item__icon {
      img {
        width: 45px;
      }
    }
  }

  .bt-selecionar-images {
    display: inline-flex !important;
    align-items: center;
    width: auto;
    margin: 0 10px 0 0;
  }

  .drop-zone {
    background-image: url(../../assets/upload-icon.png);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: center 50px;

    border: 2px dotted #ddd;
    padding: 100px 30px 60px;
    background-color: #f0f0f0;
    text-align: center;
    margin-top: 15px;
  }

  .theme--light.v-sheet {
    background-color: #ddd;
  }

  .v-image__image {
    background-color: #e5e5e5;
  }

  .v-application--is-ltr .v-list-item {
    background-color: #eee;
    margin-bottom: 1px;

    &__icon:first-child {
      margin-right: 5px;

      .v-icon {
        margin-right: 10px;

        &.success--text {
          opacity: 1;
        }
      }
    }
  }
</style>
