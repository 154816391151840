<template>
  <div
    class="main-header__container"
  >
    <button
      class="main-header__menu-trigger"
      :class="{ '--is-expanded': isExpanded }"
      @click="toggleMenu()"
    >
      <v-icon>menu</v-icon>
    </button>

    <div class="main-header__profile">
      <h2>
        <span>{{ currentUser.name }}</span>
        <small><b>{{ currentUser.account.name }}</b> - {{ currentUser.role }}</small>
      </h2>
      <div
        class="main-header__profile__avatar"
        :style="`background-image: url('${currentUser.fileAvatar.url}')`"
      />
    </div>

    <v-navigation-drawer
      class="main-header__drawer"
      v-model="expandOnHover"
      :expand-on-hover="expandOnHover"
      :mini-variant="miniVariant"
      :right="right"
      :permanent="permanent"
      :src="bg"
      absolute
    >
      <h1 class="logo">Maestro</h1>
      <ul class="main-header__drawer__list" >

      </ul>

      <ul class="main-header__drawer__list">
        <li>
          <router-link
            :to="'/home'"
            class="diagonal-right"
          >
            <v-icon>home</v-icon>
            <span>Home</span>
          </router-link>
        </li>

        <li v-for="item in menu" :key="item._id">
          <router-link
            :to="'/' + item.route"
            replace
            exact
            class="diagonal-right"
          >
            <v-icon>{{ item.icon }}</v-icon>
            <span>{{ item.name }}</span>
          </router-link>

          <ul v-if="item.children" class="main-header__menu-children">
            <li v-for="child in item.children" :key="child._id">
              <router-link
                :to="'/' + child.route"
                replace
                exact
                class="diagonal-right"
              >
                <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                <span>{{ child.name }}</span>
              </router-link>
            </li>
          </ul>
        </li>

        <li>
          <router-link
            to="/logout"
            class="diagonal-right"
          >
            <v-icon>exit_to_app</v-icon>
            <span>Logout</span>
          </router-link>
        </li>
      </ul>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MainHeader',
  data () {
    return {
      color: 'primary',
      right: false,
      permanent: true,
      miniVariant: true,
      expandOnHover: true,
      background: false,
      drawer: false,
      clipped: false,
      btnLogoutLoading: false,
      btnLogoutDisabled: false,
      formErrors: null,
      modules: [],
      menu: []
    }
  },
  computed: {
    ...mapGetters(
      'authentication',
      ['isAuthenticated', 'currentUser']
    ),
    bg () {
      return this.background ? 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg' : undefined
    },
    isExpanded () {
      return !this.miniVariant
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.currentUser.menu) {
        this.menu = this.currentUser.menu.items

        this.menu.forEach(item => {
          item.name = item.title
          item.route = item.description

          item.children.forEach(child => {
            child.name = child.title
            child.route = child.description
          })
        })
      } else {
        this.menu = this.currentUser.modules
      }
    })
  },
  methods: {
    ...mapActions(
      'authentication',
      ['logout']
    ),
    toggleMenu () {
      this.expandOnHover = !this.expandOnHover
      this.miniVariant = !this.miniVariant
      this.$emit('expand', !this.miniVariant)
    }
  }
}
</script>
<style lang="scss">
  .main-header {
    &__container {
      display: flex;
      justify-content: space-between;
      padding: 5px 26px 10px 20px;
      position: fixed;
      width: 100vw;
      z-index: 20000;
      background-color: #283139;
    }

    &__menu-trigger {
      margin: 0 50px;

      &.--is-expanded {
        margin-left: 170px;
      }

      .v-icon {
        color: #fff;
      }
    }

    &__profile {
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      overflow: hidden;
      transform: .2s all;

      &__avatar {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        background-size: cover;
        flex-shrink: 0;
        margin-left: 10px
      }

      h2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        margin-top: 0;
        width: calc(100% - 55px);
        display: flex;
        flex-direction: column;
        text-align: right;
        color: white;

        small {
          font-weight: 300;
          font-size: 10px;
          text-transform: uppercase;
          margin-top: -5px;
          line-height: 14px;
          color: white;

          b {
            font-weight: 900;
            color: white;
          }
        }
      }
    }

    &__logo {
      width: 70px;

      &__container {
        position: absolute;
        background: white;
        top: -44px;
        padding: 50px 40px 3px;
        border-radius: 59px;
        left: calc(50vw - 150px / 2);
        box-shadow: 0 5px 9px 2px rgba(0,0,0,.12)
      }
    }

    &__drawer {
      max-width: 180px;
      position: fixed;

      .logo {
        background-repeat: no-repeat;
        background-size: 40px ;
        background-position: 3px 18px;
        background-image: url('../../assets/images/logo-maestro.png');
        text-indent: 500px;
        overflow: hidden;
        transition: .2s background-position ease-out;
        height: 59px;
      }

      &.v-navigation-drawer {
        background-color: #283139 !important;
        height: 100vh !important;
        z-index: 10;

        &--mini-variant {
          .main-header__drawer__list {
            li {
              white-space: nowrap;
              overflow: hidden;
              transform: .2s all;
            }
          }
        }

        &--open:not(.v-navigation-drawer--mini-variant),
        &--is-mouseover {
          .logo {
            background-position: 60px 18px;
            transition: .3s background-position ease-out;
          }

          .main-header__drawer__list li {
            width: 100%;
          }
        }
      }

      &__list {
        * {
          text-decoration: none;
          list-style: none;
          padding: 0;
          margin: 0;
        }

        padding-left: 0 !important;
        margin-top: 25px;

        > li {
          color: white;
          text-decoration: none;

          > a {
            color: white;
            display: flex;
            align-items: center;
            padding: 10px;

            > .v-icon {
              margin-left: 7px;
              margin-right: 14px;
            }
          }

          > ul {
            border-left: 1px dotted white;
            margin-left: 27px;
            margin-bottom: 10px;
            width: calc(100% - 40px);

            > li {
              width: auto;
              padding-right: 20px;
              padding-bottom: 5px;

              a {
                color: white;
              }

              span {
                white-space: unset;
              }

              &:last-of-type {
                padding-bottom: 0;
              }
            }
          }

          &:last-child {
            position: fixed;
            bottom: 10px;
          }

          .v-icon {
            margin-right: 10px;
            font-size: 25px;
            width: 32.5px;
            opacity: .5;
            color: white;

            &::after {
              width: 32.5px;
            }
          }

          span {
            white-space: nowrap;
          }

          &:hover {
            background-color: #006eab;
            span, .v-icon {
              color: #fff
            }
          }
        }
      }
    }
  }

  // .diagonal-right{
  //   background-image: linear-gradient(to right,#006eab 49.5%, transparent 50%);
  //   background-size: 200% 200%;
  //   background-position: right;
  // }

  .diagonal-right:hover {
    background-position: left;
  }

  @media screen and (min-width: 768px) {
    .main-header {
      &__container {
        position: relative;
        z-index: inherit;
      }
    }
  }
</style>
