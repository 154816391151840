
<template>
  <div class="m-single-file__container">
    <h3>{{ fieldData.label }}</h3>
    <div v-show="$refs[refName] && $refs[refName].dropActive" class="drop-active">
      <h3>Arraste os arquivos aqui para enviar</h3>
    </div>

    <div class="upload">
      <div
        v-if="!files.length"
        class="drop-zone"
      >Arraste os arquivos aqui</div>

      <!-- Single file -->
      <section class="m-single-file__contents" v-if="files">
        <ul class="m-single-file__list">
          <li v-for="file in files" :key="file.id" class="m-single-file__file">
            <img
              v-if="file.url"
              :src="file.url"
              class="m-single-file__image"
            />

            <div class="m-single-file__file-details">
              <h4>
                <a
                  :href="file.url"
                  target="_blank"
                >{{ file.uploaded ? file.path : file.name }}</a>
              </h4>
              <small>
                <v-icon
                  v-if="file.uploaded"
                  color="success"
                >cloud_upload</v-icon>
                <small>{{ `${Math.ceil(file.size/1024)}kB` }}</small>
              </small>
            </div>

            <v-icon
              class="m-single-file__action"
              @click.prevent="removeItem(file)"
            >delete</v-icon>
          </li>
        </ul>
      </section>

      <v-progress-linear
        v-if="uploading"
        color="blue"
        indeterminate
      />

      <div class="m-single-file__filezone btn-group">
        <file-upload
          class="btn btn-primary"
          extensions="gif,jpg,jpeg,png,webp"
          accept="image/png,image/gif,image/jpeg,image/webp"
          :multiple="false"
          :drop="true"
          :drop-directory="true"
          :size="1024 * 1024 * 10"
          @input-file="inputFile"
          :ref="'upload' + fieldData.model"
          :class="'upload' + fieldData.model"
          :input-id="'upload' + fieldData.model"
        >
          <i class="fa fa-plus"></i>
          {{ this.files.length ? 'Trocar' : 'Selecionar' }} arquivo
        </file-upload>

        <button
          v-if="files && files.length && !files[0].uploaded"
          type="button"
          class="btn btn-success"
          :disabled="uploading"
          @click="upload()"
        >
          <i
            class="fa fa-arrow-up"
            aria-hidden="true"
          />
          Start Upload
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .m-single-file__container {
    .drop-zone {
      background-position: center calc(50% - 10px);
      padding: 140px 30px 60px;

      .v-navigation-drawer & {
        background-position: 20px 10px;
        padding: 21px 30px 30px 80px;
        border: 2px dotted #ddd;
        text-align: left;
      }
    }

    .m-single-file {
      &__contents {
        background-color: #eee;
        padding: 20px;
      }

      &__list {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      &__file {
        display: flex;
        position: relative;
      }

      &__image {
        width: 100px;
        flex-grow: 0;
        height: auto;
        margin-right: 10px;
        border-radius: 5px;
        box-shadow: 2px 1px 5px rgba(0,0,0,.3);
      }

      &__file-details {
        width: calc(100% - 120px);

        h4 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
          width: 100%;
        }
      }

      &__action {
        flex-grow: 0;
        position: absolute;
        right: -25px;
        bottom: -10px;
      }
    }

    .v-icon {
      margin-right: 10px;
      opacity: 1;
    }

    .m-single-file__filezone {
      display: flex;
      justify-content: space-between;
      margin-top: 20px
    }

    .v-progress-linear {
      margin-top: -3px;
    }

    .drop-active {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: fixed;
      z-index: 9999;
      opacity: .6;
      text-align: center;
      background: #000;
    }

    .drop-active h3 {
      margin: -.5em 0 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      font-size: 40px;
      color: #fff;
      padding: 0;
    }
  }
</style>

<script>
import mUploadMixin from './uploadMixin'

export default {
  name: 'mSingleFile',
  mixins: [mUploadMixin]
}
</script>
