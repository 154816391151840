const entity = {
  name: {
    singular: 'Usuário',
    plural: 'Usuários'
  },
  collection: 'users',
  tableHeaders: [
    { text: 'Nome', value: 'name' },
    { text: 'Email', value: 'email' },
    { text: 'Celular', value: 'cellphone' },
    { text: 'Permissão', value: 'role' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    fileAvatar: '',
    name: '',
    email: '',
    cellphone: '',
    role: 'writer',
    status: 'denied',
    account: ''
  },
  schema: {
    groups: [
      {
        name: 'Dados',
        fields: [
          {
            type: 'single-file',
            label: 'Avatar',
            model: 'fileAvatar',
            hideInput: true,
            size: 'lg12'
          },
          {
            type: 'text-field',
            label: 'Nome',
            model: 'name',
            required: true,
            size: 'lg12'
          },
          {
            type: 'text-field',
            label: 'Email',
            model: 'email',
            required: true,
            size: 'lg12'
          },
          {
            type: 'text-field',
            inputType: 'text',
            label: 'Celular',
            model: 'cellphone',
            required: false,
            size: 'lg4'
          },
          {
            type: 'select',
            label: 'Role',
            model: 'role',
            items: [
              { title: 'Administrador', _id: 'admin' },
              { title: 'Supervisor', _id: 'supervisor' },
              { title: 'Redator', _id: 'writer' }
            ],
            size: 'lg4'
          },
          {
            type: 'switch',
            label: 'Status',
            model: 'status',
            textOn: 'Aprovado',
            textOff: 'Inativo',
            valueOn: 'approved',
            valueOff: 'denied',
            size: 'lg4'
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    name: 'users',
    path: '/users',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'users',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    name: 'users_new',
    path: '/users/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'users',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    name: 'users_edit',
    path: '/users/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'users',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }
]
