const messagesStore = {
  namespaced: true,
  state: {
    message: null
  },
  mutations: {
    defineMessage: (state, message) => {
      state.message = message
    },
    clearMessage: (state) => {
      state.message = null
    }
  },
  actions: {
    defineMessage: ({ commit }, message) => {
      commit('defineMessage', message)
    },

    clearMessage: ({ commit }) => {
      commit('clearMessage')
    }
  },
  getters: {
    message: state => state.message
  }
}

export default messagesStore
