<template>
  <v-text-field-money
    v-if="fieldData.inputMask === 'money'"
    class="m-input__money"
    :ref="fieldData.model"
    v-model="proxyValue"
    :label="fieldData.label"
    :required="fieldData.required"
    :properties="{
      prefix: fieldData.inputMaskOptions.prefix || 'R$',
      readonly: fieldData.inputMaskOptions.readonly || false,
      disabled: fieldData.inputMaskOptions.disabled || false,
      outlined: fieldData.inputMaskOptions.outlined || false,
      clearable: fieldData.inputMaskOptions.clearable || false,
      placeholder: fieldData.inputMaskOptions.placeholder || ' ',
    }"
    :options="{
      locale: fieldData.inputMaskOptions.locale || 'pt-BR',
      length: fieldData.inputMaskOptions.length || 11,
      precision: fieldData.inputMaskOptions.precision || 6,
      empty: fieldData.inputMaskOptions.empty || null,
    }"
  />

  <v-text-field-percent
    v-else-if="fieldData.inputMask === 'percent'"
    :ref="fieldData.model"
    v-model="proxyValue"
    :label="fieldData.label"
    :required="fieldData.required"
    :properties="{
      suffix: '%',
      readonly: false,
      disabled: false,
      outlined: false,
      clearable: false,
      placeholder: '',
    }"
    :options="{
      locale: 'pt-BR',
      length: 3,
      precision: 2,
      empty: null,
    }"
  />

  <v-text-field-integer
    v-else-if="fieldData.inputMask === 'integer'"
    :ref="fieldData.model"
    v-model="proxyValue"
    :label="fieldData.label"
    :required="fieldData.required"
    :properties="{
      readonly: false,
      disabled: false,
      outlined: false,
      clearable: false,
      placeholder: '',
    }"
    :options="{
      inputMask: '#########',
      outputMask: '#########',
      empty: null,
      applyAfter: false,
    }"
  />

  <v-text-field-simplemask
    v-else-if="fieldData.inputMask && fieldData.inputMask.includes('##')"
    :ref="fieldData.model"
    v-model="proxyValue"
    :label="fieldData.label"
    :required="fieldData.required"
    :properties="{
      prefix: '',
      suffix: '',
      readonly: false,
      disabled: false,
      outlined: false,
      clearable: false,
      placeholder: '',
    }"
    :options="{
      inputMask: fieldData.inputMask,
      outputMask: fieldData.outputMask,
      empty: null,
      applyAfter: false,
      alphanumeric: true,
      lowerCase: false,
    }"
    @blur="callback"
  />

  <v-text-field
    v-else
    :label="fieldData.label"
    :ref="fieldData.model"
    v-model="proxyValue"
    :hint="fieldData.hint"
    :disabled="fieldData.readOnly"
  />
</template>
<script>
import mMixin from './mixin'
import axios from 'axios'

export default {
  name: 'm-input',
  mixins: [mMixin],
  data () {
    return {
      callback: function () {
        return ''
      }
    }
  },
  mounted () {
    if (this.fieldData.model === 'zipcode') this.callback = this.getAddressByCEP
  },
  methods: {
    async getAddressByCEP () {
      if (this.fieldData.model === 'zipcode' && this.proxyValue.length === 8) {
        const response = await axios.get(`https://ws.apicep.com/cep/${this.proxyValue}.json`)
        // console.log(response)

        if (!response.erro && response.data) this.$emit('child-data', { event: 'AddressFecthed', data: response.data })
      }
    }
  }
}
</script>
<style lang="scss">
  .m-input__money input {
    text-align: right;
  }
</style>
