import { authService } from '../services/AuthService'
import { localPersistence } from '@/services/LocalPersistenceService'

const authenticationStore = {
  namespaced: true,
  state: {
    authenticatedUser: null
  },
  mutations: {
    setAuthenticatedUser: (state, user) => {
      delete user.password
      state.authenticatedUser = user
    },
    logoutUser: (state) => {
      state.authenticatedUser = null
    }
  },
  actions: {
    authenticate: async ({ commit }, credentials) => {
      try {
        let user = await authService.login(credentials)
        user.id = user.userId
        commit('setAuthenticatedUser', { ...user })

        const { modules, token } = user
        delete user.modules
        delete user.token

        localPersistence
          .set('mstrToken', token, '1d')
          .set('mstrModules', JSON.stringify(modules), '1d')
          .set('mstrAcId', user.accountId, '30d')
          .set('mstrAuthUsr', JSON.stringify(user), '1d')
        return user
      } catch (error) {
        throw new Error(error)
      }
    },

    register: async ({ dispatch }, credentials) => {
      let user = await authService.register(credentials)
      return user
    },

    forgotPassword: async ({ dispatch }, credentials) => {
      let response = await authService.forgotPassword(credentials)
      return response
    },

    validateResetPasswordToken: async ({ dispatch }, token) => {
      let response = await authService.validateResetPasswordToken(token)
      return response
    },

    confirmAccount: async ({ dispatch }, token) => {
      let response = await authService.confirmAccount(token)
      return response
    },

    passwordReset: async ({ dispatch }, credentials) => {
      let response = await authService.passwordReset(credentials)
      return response
    },

    getFromLocalPersistence: ({ commit }) => {
      const user = localPersistence.get('mstrAuthUsr')
      const token = localPersistence.get('mstrToken')
      const modules = localPersistence.get('mstrModules')

      if (!user || !token || !modules) return null

      user.modules = JSON.parse(modules)
      user.token = token

      if (user) commit('setAuthenticatedUser', user)
      return user
    },

    logout: async ({ commit }) => {
      localPersistence.remove('mstrAuthUsr')
      localPersistence.remove('mstrToken')
      localPersistence.remove('mstrModules')
      commit('logoutUser')
    }
  },

  getters: {
    isAuthenticated: (state) => !!state.authenticatedUser,
    currentUser: (state) => state.authenticatedUser
  }
}

export default authenticationStore
