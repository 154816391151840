const states = [
  { _id: 'AL' },
  { _id: 'AK' },
  { _id: 'AZ' },
  { _id: 'AR' },
  { _id: 'CA' },
  { _id: 'CO' },
  { _id: 'CT' },
  { _id: 'DE' },
  { _id: 'FL' },
  { _id: 'GA' },
  { _id: 'HI' },
  { _id: 'ID' },
  { _id: 'IL' },
  { _id: 'IN' },
  { _id: 'IA' },
  { _id: 'KS' },
  { _id: 'KY' },
  { _id: 'LA' },
  { _id: 'ME' },
  { _id: 'MD' },
  { _id: 'MA' },
  { _id: 'MI' },
  { _id: 'MN' },
  { _id: 'MS' },
  { _id: 'MO' },
  { _id: 'MT' },
  { _id: 'NE' },
  { _id: 'NV' },
  { _id: 'NH' },
  { _id: 'NJ' },
  { _id: 'NM' },
  { _id: 'NY' },
  { _id: 'NC' },
  { _id: 'ND' },
  { _id: 'OH' },
  { _id: 'OK' },
  { _id: 'OR' },
  { _id: 'PA' },
  { _id: 'RI' },
  { _id: 'SC' },
  { _id: 'SD' },
  { _id: 'TN' },
  { _id: 'TX' },
  { _id: 'UT' },
  { _id: 'VT' },
  { _id: 'VA' },
  { _id: 'WA' },
  { _id: 'WV' },
  { _id: 'WI' },
  { _id: 'WY' }
]

const pickupDeliveryStatus = ['new', 'Pending', 'Confirmed', 'Done']

const paymentStatusOptions = ['Pending', 'Scheduled', 'Done']

const loadStatus = [
  { _id: 'New', title: 'New' },
  { _id: 'AwaitingPickup', title: 'AwaitingPickup' },
  { _id: 'InProgress', title: 'InProgressg' },
  { _id: 'Delivered', title: 'Delivered' },
  { _id: 'Archived', title: 'Archived' }
]

const inspectionType = [
  { _id: 'standard', title: 'standard' },
  { _id: 'advanced', title: 'advanced' },
  { _id: 'aiag', title: 'aiag' }
]

const businessType = [
  { _id: 'DEALER', title: 'Dealer' },
  { _id: 'PRIVATE', title: 'Private' },
  { _id: 'AUCTION', title: 'Auction' },
  { _id: 'REPO_YARD', title: 'Repo Yard' },
  { _id: 'PORT', title: 'Port' },
  { _id: 'BUSINESS', title: 'Business' }
]

const drivers = [
  { _id: 'pedro', title: 'RJR - Pedro Silva' },
  { _id: 'thiago', title: 'RJR - Thiago Sestari' },
  { _id: 'joao', title: 'João de Deus' },
  { _id: 'mateus', title: 'Mateus Silva' },
  { _id: 'judas', title: 'Judas o Outro' },
  { _id: 'zebedeu', title: 'Ze Bedeu' },
  { _id: 'marcos', title: 'Marcos Apts' }
]

const clients = [
  { _id: 'new', title: 'Novo' },
  { _id: 'marcos', title: 'Massrec LLC' }
]

const carriers = [
  { _id: 'new', title: 'RJR Reliable' },
  { _id: 'marcos', title: 'José da Silva LLC' }
]

const loadBoards = [
  { _id: 'carsa', title: 'CarsArrive' },
  { _id: 'one', title: '1Dispatch' },
  { _id: 'self', title: 'Self - RJR' },
  { _id: 'other', title: 'Other' }
]

const paymentTerms = [
  { _id: '5_days', title: '5 days' },
  { _id: '7_days', title: '7 days' },
  { _id: '10_days', title: '10 days' },
  { _id: '15_days', title: '15 days' },
  { _id: '20_days', title: '20 days' },
  { _id: '30_days', title: '30 days' },
  { _id: '40_days', title: '40 days' },
  { _id: '45_days', title: '45 days' },
  { _id: 'ach', title: 'Ach' },
  { _id: 'cash_on_pickup', title: 'Cash on pickup' },
  { _id: 'check_on_pickup', title: 'Check on pickup' },
  { _id: 'cash_on_delivery', title: 'Cash on delivery' },
  { _id: 'check_on_delivery', title: 'Check on delivery' },
  { _id: 'comchek', title: 'Comchek' },
  { _id: 'quick_pay', title: 'Quick pay' },
  { _id: 'other', title: 'Other' }
]

const paymentMethods = [
  { _id: 'cash', title: 'cash' },
  { _id: 'check', title: 'check' },
  { _id: 'cashiers_check', title: 'cashiers_check' },
  { _id: 'money_order', title: 'money_order' },
  { _id: 'comchek', title: 'comchek' },
  { _id: 'credit_card', title: 'credit_card' },
  { _id: 'direct_deposit', title: 'direct_deposit' },
  { _id: 'other', title: 'other' }
]

const transportType = [
  { _id: 'OPEN', title: 'Open' },
  { _id: 'ENCLOSED', title: 'Enclosed' },
  { _id: 'DRIVEAWAY', title: 'Driveaway' }
]

const vehicleType = [
  { _id: '2_door_coupe', title: '2 door coupe' },
  { _id: '4_door_pickup', title: '4 door pickup' },
  { _id: 'atv', title: 'Atv' },
  { _id: 'boat', title: 'Boat' },
  { _id: 'freight', title: 'Freight' },
  { _id: 'heavy_machinery', title: 'Heavy machinery' },
  { _id: 'livestock', title: 'Livestock' },
  { _id: 'motorcycle', title: 'Motorcycle' },
  { _id: 'pickup', title: 'Pickup' },
  { _id: 'rv', title: 'Rv' },
  { _id: 'sedan', title: 'Sedan' },
  { _id: 'suv', title: 'Suv' },
  { _id: 'trailer_5th_wheel', title: 'Trailer 5th wheel' },
  { _id: 'trailer_bumper_pull', title: 'Trailer bumper pull' },
  { _id: 'trailer_gooseneck', title: 'Trailer gooseneck' },
  { _id: 'truck_daycab', title: 'Truck daycab' },
  { _id: 'truck_sleeper', title: 'Truck sleeper' },
  { _id: 'van', title: 'Van' },
  { _id: 'other', title: 'Other' }
]

const data = {
  inspectionType,
  states,
  businessType,
  paymentMethods,
  paymentTerms,
  transportType,
  vehicleType,
  carriers,
  loadBoards,
  clients,
  drivers,
  pickupDeliveryStatus
}

const entity = {
  name: {
    singular: 'Load',
    plural: 'Loads'
  },
  collection: 'loads',
  tableHeaders: [
    { text: 'Número', value: 'number' },
    { text: 'Cliente', value: 'client.name' },
    { text: 'Início', value: 'startDate', type: 'date' },
    { text: 'Entrega', value: 'endDate', type: 'date' },
    { text: 'Status', value: 'status' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    orderNumber: '',
    loadBoardId: '',
    loadBoard: '',
    loadBoardStatus: 'new',
    status: 'new',
    price: '',
    paymentStatus: 'pending',
    brokerFee: '',
    instructions: '',

    broker: '',
    driver: '',
    carrier: '',

    pickup: {
      scheduledDate: '',
      workingHours: '',
      status: 'pending',
      realizedDate: '',

      contactName: '',
      contactEmail: '',
      contactPhone: '',
      contactMobilePhone: '',

      address: '',
      city: '',
      state: '',
      zip: '',
      businessType: '',
      notes: ''
    },

    delivery: {
      scheduledDate: '',
      workingHours: '',
      status: 'pending',
      realizedDate: '',

      contactName: '',
      contactEmail: '',
      contactPhone: '',
      contactMobilePhone: '',

      address: '',
      city: '',
      state: '',
      zip: '',
      businessType: '',
      notes: ''
    },

    vehicles: [],
    payments: []
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      { label: 'Order Number', model: 'orderNumber', size: 'lg6' },
      { label: 'Status', model: 'status', size: 'lg6', type: 'select', items: loadStatus },

      { label: 'LoadBoard', model: 'loadBoard', size: 'lg4', readonly: true },
      { label: 'LoadBoardId', model: 'loadBoardId', size: 'lg4', readonly: true },
      { label: 'LoadBoardStatus', model: 'loadBoardStatus', size: 'lg4' },

      { label: 'Carrier', model: 'carrier', type: 'select', collection: 'carriers', size: 'lg6' },
      { label: 'Driver', model: 'driver', type: 'select', collection: 'drivers', size: 'lg6' },

      { label: 'Broker', model: 'broker', type: 'select', collection: 'brokers', size: 'lg8' },
      { label: 'Broker Fee', model: 'brokerFee', size: 'lg4' },

      { label: 'Price', model: 'price', size: 'lg6' },
      { label: 'Payment Status', model: 'paymentStatus', size: 'lg6', type: 'select', items: paymentStatusOptions },
      { label: 'Instructions', model: 'instructions', size: 'lg12', type: 'textarea', hint: 'Driver will see this info.' }
    ]
  },
  schema: {
    groups: [
      {
        name: 'PickUp',
        fields: [
          { label: 'Pick up date', model: 'pickup.scheduledDate', type: 'date-picker', size: 'lg3' },
          { label: 'Working Ours', model: 'pickup.workingHours', size: 'lg3' },
          { label: 'Status', model: 'pickup.status', type: 'select', items: data.pickupDeliveryStatus, size: 'lg3' },
          { label: 'Picked up at', model: 'pickup.realizedDate', type: 'date-picker', size: 'lg3' },

          { label: 'Contact Name', model: 'pickup.contactName', size: 'lg9' },
          { label: 'Business Type', model: 'pickup.businessType', type: 'select', items: data.businessType, size: 'lg3' },
          { label: 'Contact Email', model: 'pickup.contactEmail', size: 'lg6' },
          { label: 'Contact Phone', model: 'pickup.contactPhone', inputMask: '(###) ###-####', outputMask: '##########', size: 'lg3' },
          { label: 'Contact MobilePhone', model: 'pickup.contactMobilePhone', inputMask: '(###) ###-####', outputMask: '##########', size: 'lg3' },

          { label: 'Address', model: 'pickup.address', size: 'lg6' },
          { label: 'City', model: 'pickup.city', size: 'lg3' },
          { label: 'State', model: 'pickup.state', size: 'lg1', type: 'select', items: data.states },
          { label: 'Zip', model: 'pickup.zip', size: 'lg2', inputMask: '#####', outputMask: '#####' },

          { label: 'Notes', model: 'pickup.notes', type: 'textarea', size: 'lg12' }
        ]
      },
      {
        name: 'Delivery',
        fields: [
          { label: 'Delivery date', model: 'delivery.scheduledDate', type: 'date-picker', size: 'lg3' },
          { label: 'Working Ours', model: 'delivery.workingHours', size: 'lg9' },
          { label: 'Status', model: 'delivery.status', type: 'select', items: data.pickupDeliveryStatus, size: 'lg6' },
          { label: 'Delivered at', model: 'delivery.realizedDate', type: 'date-picker', size: 'lg3' },

          { label: 'Contact Name', model: 'delivery.contactName', size: 'lg9' },
          { label: 'Business Type', model: 'delivery.businessType', type: 'select', items: data.businessType, size: 'lg3' },
          { label: 'Contact Email', model: 'delivery.contactEmail', size: 'lg6' },
          { label: 'Contact Phone', model: 'delivery.contactPhone', inputMask: '(###) ###-####', outputMask: '##########', size: 'lg3' },
          { label: 'Contact MobilePhone', model: 'delivery.contactMobilePhone', inputMask: '(###) ###-####', outputMask: '##########', size: 'lg3' },

          { label: 'Address', model: 'delivery.address', size: 'lg6' },
          { label: 'City', model: 'delivery.city', size: 'lg3' },
          { label: 'State', model: 'delivery.state', size: 'lg1', type: 'select', items: data.states },
          { label: 'Zip', model: 'delivery.zip', size: 'lg2', inputMask: '#####', outputMask: '#####' },

          { label: 'Notes', model: 'delivery.notes', type: 'textarea', size: 'lg12' }
        ]
      },
      {
        name: 'Vehicles',
        fields: [
          {
            label: 'Vehicles',
            type: 'array',
            model: 'vehicles',
            arrayOf: {
              fields: [
                { label: 'Make', model: 'make', size: 'lg2' },
                { label: 'Model', model: 'model', size: 'lg2' },
                { label: 'Year', model: 'year', size: 'lg1' },
                { label: 'Color', model: 'color', size: 'lg1' },
                { label: 'Vin', model: 'vin', size: 'lg2' },
                { label: 'Is Inoperable', model: 'is_inoperable', type: 'switch', size: 'lg2' },
                { label: 'Info', model: 'info', size: 'lg12' },
                { label: 'Posição', model: 'position', required: true }
              ]
            }
          }
        ]
      },
      {
        name: 'Payments',
        fields: [{
          type: 'array',
          label: 'Payments',
          model: 'payments',
          arrayOf: {
            fields: [
              { label: 'Terms', model: 'terms', type: 'select', items: data.paymentTerms, size: 'lg2' },
              { label: 'Method', model: 'method', type: 'select', items: data.paymentMethods, size: 'lg2' },
              { label: 'Amount', model: 'amount', size: 'lg2' },
              { label: 'Ref. Number', model: 'reference_number', size: 'lg2' },
              { label: 'Sent', type: 'date-picker', model: 'sent_date', size: 'lg2' },
              { label: 'Notes', model: 'notes', size: 'lg12' },
              { label: 'Posição', model: 'position', required: true }
            ]
          }
        }]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/loads',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'loads',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'loads' */ '@/modules/loads/Index.vue')
  }, {
    path: '/loads/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'loads',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'loads' */ '@/modules/loads/Index.vue')
  }, {
    path: '/loads/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'loads',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'loads' */ '@/modules/loads/Index.vue')
  }
]
