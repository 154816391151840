import { mapGetters } from 'vuex'

export default {
  props: ['fieldData', 'value'],
  computed: {
    ...mapGetters({
      currentUser: 'authentication/currentUser'
    }),
    proxyValue: {
      get () { return this.value },
      set (value) {
        const field = this.fieldData.model
        const emittableValue = value
        this.$emit('input', { field, value: emittableValue })
      }
    }
  }
}
