import { addYears } from 'date-fns'

const entity = {
  name: {
    singular: 'Conta',
    plural: 'Contas'
  },
  collection: 'accounts',
  tableHeaders: [
    { text: 'Nome', value: 'name' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    name: '',
    active: false,
    token: '',
    accountId: '',
    modules: [],
    dateIn: new Date(),
    dateOut: addYears(new Date(), 10)
  },
  config: {
    name: 'Config',
    fields: [
      {
        type: 'text-field',
        label: 'Nome',
        model: 'name',
        required: true
      },
      {
        type: 'text-field',
        label: 'Código',
        model: 'code',
        required: true
      },
      {
        type: 'token',
        label: 'Token',
        model: 'token'
      },
      {
        type: 'select',
        multiple: true,
        label: 'Módulos',
        model: 'modules',
        required: true,
        collection: 'modules'
      },
      {
        type: 'date-picker',
        label: 'Início',
        model: 'dateIn',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'date-picker',
        label: 'Término',
        model: 'dateOut',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'switch',
        label: 'Active',
        model: 'active'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Usuários',
        groupType: 'list',
        entity: 'users',
        itemsPerPage: 50,
        fields: ['name', 'email', 'role', 'status'],
        tableHeaders: [
          { text: 'Nome', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Perfil', value: 'role' },
          { text: 'Status', value: 'status' },
          { text: 'Actions', value: 'action', sortable: false }
        ],
        relatedCollection: 'accounts',
        relatedCollectionIdField: 'accountId',
        editOnDrawer: true,
        addButton: true
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/accounts',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'accounts',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'accounts' */ '@/modules/accounts/Index.vue')
  }, {
    path: '/accounts/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'accounts',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'accounts' */ '@/modules/accounts/Index.vue')
  }, {
    path: '/accounts/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'accounts',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'accounts' */ '@/modules/accounts/Index.vue')
  }
]
