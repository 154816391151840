<template>
  <section>
    <h3 class="mb-4">{{ fieldData.label }}</h3>
    <codemirror v-model="proxyValue" :options="{
      tabSize: 2,
      mode: 'text/' + fieldData.language,
      theme: 'base16-dark',
      lineNumbers: true,
      line: true
    }" />
  </section>
</template>
<script>
import mMixin from '@/components/mComponents/mixin'
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'

import 'codemirror/mode/css/css.js'
import 'codemirror/theme/base16-dark.css'

export default {
  name: 'm-code-editor',
  mounted () {
    if (!this.proxyValue) this.proxyValue = '/* Insira aqui os estilos personalizados para esta página. */'
  },
  components: {
    codemirror
  },
  mixins: [mMixin]
}
</script>
