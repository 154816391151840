<template>
  <v-combobox
    :ref="fieldData.model"
    v-model="proxyValue"
    :label="fieldData.label"
    :required="fieldData.required"
    :items="fieldData.items"
  />
</template>
<script>
import mMixin from './mixin'

export default {
  name: 'm-combobox',
  data () {
    return {
      inputModel: ''
    }
  },
  mixins: [mMixin],
  mounted () {
    this.inputModel = this.value ? this.value : ''
  }
}
</script>
