import cookie from 'vue-cookies'

const localPersistence = {
  remove: key => cookie.remove(key),
  set: (key, obj) => cookie.set(key, obj),
  get: (key) => cookie.get(key),
  has: key => !!cookie.get(key),
  toJSON: () => JSON.stringify(cookie)
}

export { localPersistence }
