<template>
  <div class="m-array-line__container">
    <span class="m-array-line__index">{{ value.position + 1 }}</span>
    <v-icon class="m-array-line__drag-icon">drag_indicator</v-icon>
    <v-layout>
      <v-flex
        class="m-form__field-container"
        v-for="(field, idz) in fields"
        :key="'field' + idz"
        :class="`field-${field.type} field-${field.model} ${field.size}`"
      >
        <component
          v-if="shouldShow(field)"
          :is="currentComponent(field.type)"
          :class="`m-array-line__field field-${field.type} field-${field.model}`"
          :key="'field-' + idz"
          v-bind="currentProperties(field)"
          @input="updateModel"
        />
      </v-flex>
    </v-layout>
    <v-btn
      @click="$emit('delete')"
      class="m-array-line__delete-button"
      icon
    >
      <v-icon>delete</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'm-array-line',
  data () {
    return {
      items: [],
      tempValue: {}
    }
  },
  props: ['item', 'fields', 'value', 'index', 'can-add-subitem'],
  mounted () {
    this.tempValue = this.value
  },
  methods: {
    endDrag (evt) {
      this.value.forEach((item, idx) => {
        item.position = idx
      })
      // const target = this.value.find(item => item.position === evt.oldIndex)
      // target.position = this.newIndex
    },

    shouldShow (field) {
      if (!field.condition) return true
      return field.condition(this.proxyValue)
    },

    currentComponent (fieldType) {
      if (!fieldType) return 'm-input'

      const components = {
        'editor': 'm-editor',
        'text-field': 'm-input',
        'checkbox': 'm-checkbox',
        'combo': 'm-combobox',
        'switch': 'm-switch',
        'select': 'm-select',
        'array': 'm-array',
        'date-picker': 'm-date'
      }

      return components[fieldType]
    },

    currentProperties (field) {
      const properties = { fieldData: field }
      if (this.value) properties.value = this.tempValue[field.model]
      return properties
    },

    updateModel (evt) {
      // console.log(evt)
      this.tempValue[evt.field] = evt.value
      const val = { index: this.index, ...this.tempValue }
      // console.log(val)
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss">
.m-array-line {
  &__container {
    position: relative;
    margin-bottom: 2px;
    padding: 10px 30px;
    background: #e6e6e6;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;

    .layout {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-end;
    }

    & > div {
      padding-top: 0;
    }

    & > i {
      margin-left: 20px;
    }

    .v-input.field-icon {
      width: 120px;
    }

    // Array inside an array
    .m-array__container {
      padding-top: 0;
      margin-top: 0;

      .m-array__header {
        .m-array__add-button,
        .m-array__delete-button {
          border-radius: 50%;
          left: 80px;
          right: auto;
          width: 25px;
          height: 25px;
          min-width: 25px;
          overflow: hidden;

          & > span {
            font-size: 0;
            margin: -1px 0 0 12px;

            .v-icon {
              font-size: 17px;
            }
          }
        }
      }

      .m-array__delete-button,
      .m-array__add-button {
        width: 25px;
        height: 25px;
        min-width: 25px;
        overflow: hidden;
        background-color: transparent !important;
        box-shadow: none;
        position: absolute;

        & > span {
          font-size: 0;
          margin: -1px 0 0 12px;

          .v-icon {
            color: red;
            font-size: 17px;
          }
        }
      }

      ul {
        margin-top: 10px;

        li {
          padding: 0;
          position: relative;
          margin-bottom: 0;
        }
      }

      .m-array__label {
        font-size: 15px;
      }
    }

    .field-text-field {
      margin: 10px 0 -15px 20px;
    }

    .field-switch {
      margin: 0 0 0 0;
    }
  }

  &__field {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }

    &.field-position {
      width: 100px;
    }
  }

  &__drag-icon.v-icon {
    width: 30px;
    height: 30px;
    top: 0;
    cursor: move;
    position: absolute;
    left: -22px;
    display: none;
    font-size: 20px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #000;
    opacity: .3;
  }

  &__delete-button {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__index {
    font-size: 30px;
    font-weight: 700;
    opacity: .3;
    position: absolute;
    top: 29px;
    left: -10px;
    width: 50px;
    text-align: center;
  }
}
</style>
