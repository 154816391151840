<template>
  <v-select
    :ref="fieldData.model"
    v-model="proxyValue"
    :items="items"
    item-text="title"
    item-value="_id"
    :label="fieldData.label"
    :required="fieldData.required"
    :multiple="fieldData.multiple"
    :hint="fieldData.hint"
    persistent-hint
    class="m-select__container"
  />
</template>
<script>
import mMixin from './mixin'
import crudService from '@/services/CrudService'

export default {
  name: 'm-select',
  mixins: [mMixin],
  data () {
    return {
      items: []
    }
  },
  async mounted () {
    if (Array.isArray(this.fieldData.items)) {
      this.items = [...this.fieldData.items]
      this.items.forEach(item => {
        if (!item.title && !item.text && item._id) item.title = item._id
      })
      return
    }

    if (this.value && !this.fieldData.multiple && Array.isArray(this.value)) {
      this.proxyValue = this.value[0]
    }

    const collection = this.fieldData.collection

    const results = await crudService(collection).getList()

    if (results.empty) return

    results.data.map((item) => {
      if (!item.title && item.name) item.title = item.name
      if (this.$route.params.id !== item._id) {
        this.items.push(item)
      }
    })
  }
}
</script>
<style lang="scss">
  .v-application--is-ltr .v-list-item__action:first-child,
  .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 15px !important;
  }
</style>
