const entity = {
  name: {
    singular: 'Aluno',
    plural: 'Alunos'
  },
  collection: 'students',
  tableHeaders: [
    { text: 'Foto', value: 'picture', type: 'avatar' },
    { text: 'Nome', value: 'name' },
    { text: 'RA', value: 'studentId' },
    { text: 'Telefone', value: 'landline' },
    { text: 'Celular', value: 'cellphone' },
    { text: 'Email', value: 'email' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    gender: 'male',
    name: '',
    email: '',
    document: '',
    cellphone: '',
    landline: '',
    zipcode: '',
    city: '',
    state: '',
    address: '',
    addressNumber: '',
    address2: '',
    neighborhood: '',
    country: '',
    parents: [],
    picture: ''
  },
  config: {
    name: 'Config',
    fields: [
      {
        type: 'avatar',
        label: 'Foto',
        model: 'picture',
        hideInput: true,
        size: 'xs12 sm12 md12 lg12'
      },
      {
        type: 'radio',
        label: 'Gênero',
        model: 'gender',
        items: [
          { title: 'Masculino', _id: 'male' },
          { title: 'Feminino', _id: 'female' }
        ],
        size: 'xs12 sm12 md12 lg12'
      },
      {
        type: 'select',
        label: 'Pais/Responsáveis',
        model: 'parents',
        multiple: true,
        collection: 'parents',
        size: 'xs12 sm12 md12 lg12'
      },
      {
        type: 'text-field',
        label: 'RA',
        model: 'studentId',
        size: 'xs12 sm12 md4 lg4',
        inputMask: '###########',
        outputMask: '###########'
      },
      {
        type: 'date-picker',
        label: 'Data de Início',
        model: 'dateIn',
        size: 'xs12 sm12 md4 lg4'
      },
      {
        type: 'date-picker',
        label: 'Data de Início',
        model: 'dateOut',
        size: 'xs12 sm12 md4 lg4'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Dados pessoais',
        fields: [
          {
            type: 'text-field',
            label: 'Nome',
            model: 'name',
            size: 'xs12 sm12 md12 lg12'
          },
          {
            type: 'text-field',
            label: 'CPF',
            model: 'document',
            size: 'xs12 sm12 md6 lg6',
            inputMask: '###.###.###-##',
            outputMask: '###########'
          },
          {
            type: 'text-field',
            label: 'Email',
            model: 'email',
            size: 'xs12 sm12 md6 lg6'
          },
          {
            type: 'text-field',
            label: 'Telefone',
            model: 'landline',
            size: 'xs12 sm12 md6 lg6',
            inputMask: '(##) ####-####',
            outputMask: '###########'
          },
          {
            type: 'text-field',
            label: 'Celular',
            model: 'cellphone',
            size: 'xs12 sm12 md6 lg6',
            inputMask: '(##) #####-####',
            outputMask: '###########'
          },
          {
            type: 'text-field',
            label: 'CEP',
            model: 'zipcode',
            size: 'xs12 sm12 md3 lg3',
            inputMask: '#####-###',
            outputMask: '########'
          },
          {
            type: 'text-field',
            label: 'Endereço',
            model: 'address',
            size: 'xs12 sm12 md8 lg8'
          },
          {
            type: 'text-field',
            label: 'Número',
            model: 'number',
            size: 'xs12 sm12 md1 lg1'
          },
          {
            type: 'text-field',
            label: 'Complemento',
            model: 'address2',
            size: 'xs12 sm12 md3 lg3'
          },
          {
            type: 'text-field',
            label: 'Bairro',
            model: 'neighborhood',
            size: 'xs12 sm12 md3 lg3'
          },
          {
            type: 'text-field',
            label: 'Cidade',
            model: 'city',
            size: 'xs12 sm12 md5 lg5'
          },
          {
            type: 'text-field',
            label: 'Estado',
            model: 'state',
            size: 'xs12 sm12 md1 lg1'
          }
        ]
      },
      {
        name: 'Financeiro',
        groupType: 'list',
        entity: 'contents',
        itemsPerPage: 50,
        fields: ['title', 'slug'],
        relatedCollection: 'sections',
        relatedCollectionIdField: 'sections',
        editOnDrawer: false
      },
      {
        name: 'Provas',
        groupType: 'list',
        entity: 'contents',
        itemsPerPage: 50,
        fields: ['title', 'slug'],
        relatedCollection: 'sections',
        relatedCollectionIdField: 'sections',
        editOnDrawer: false
      },
      {
        name: 'Presença',
        groupType: 'list',
        entity: 'contents',
        itemsPerPage: 50,
        fields: ['title', 'slug'],
        relatedCollection: 'sections',
        relatedCollectionIdField: 'sections',
        editOnDrawer: false
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/students',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'students',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/students/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'students',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/students/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'students',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }
]
