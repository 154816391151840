import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_ENV_API

const axiosOptions = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  }
}

const authService = {
  getAccount: async (accountName) => {
    try {
      const response = await axios.get('/auth/get-account/' + accountName, axiosOptions)
      if (response) return response
      return null
    } catch (error) {
      throw new Error(error)
    }
  },

  login: async (user) => {
    try {
      const response = await axios.post('/auth/login', user, axiosOptions)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },

  register: async (user) => {
    try {
      const response = await axios.post('/auth/register', { user }, axiosOptions)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },

  refresh: async (user) => {
    try {
      const response = await axios.get('/auth/refresh', axiosOptions)
      // console.log('refreshedUser', response)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },

  forgotPassword: async (credentials) => {
    const email = credentials.email
    try {
      const response = await axios.post('/auth/forgot-password', { email }, axiosOptions)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },

  validateResetPasswordToken: async (token) => {
    try {
      const response = await axios.post('/auth/validate-reset-password-token', { token }, axiosOptions)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },

  confirmAccount: async (token) => {
    try {
      const response = await axios.post('/auth/confirm-account', { token }, axiosOptions)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },

  passwordReset: async (user) => {
    try {
      const response = await axios.post('/auth/password-reset', { user }, axiosOptions)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },

  updateMe: async (user) => {
    try {
      const response = await axios.post('/auth/update-me', { user }, axiosOptions)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }
}

export { authService }
