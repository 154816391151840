
<template>
  <div class="m-single-file__container">
    <h3>{{ fieldData.label }}</h3>
    <div  class="upload">
      <div class="m-single-file__filezone btn-group">
        <file-upload
          class="btn btn-primary"
          extensions="gif,jpg,jpeg,png,webp"
          accept="image/png,image/gif,image/jpeg,image/webp"
          :multiple="false"
          :drop="true"
          :drop-directory="true"
          :size="1024 * 1024 * 10"
          @input-file="inputFileAuto"
          :ref="'upload' + fieldData.model"
          :class="'upload' + fieldData.model"
          :input-id="'upload' + fieldData.model"
        >
          <div v-if="value && files && files.length" class="m--file-avatar__image">
            <img
              :key="`avatar-${fieldData.model}-${$route.params.id}-${files[0].url}`"
              :src="files[0].url"
              class="m-single-file__image"
            />
          </div>

          <div v-else class="m--file-avatar__image">
            <img
              :key="`avatar-fake`"
              src="img/avatar/boy.jpeg"
              class="m-single-file__image"
            />
          </div>
        </file-upload>
      </div>

      <v-icon
        class="m-single-file__action"
        @click.prevent="removeItem(files[0])"
      >delete</v-icon>
    </div>
  </div>
</template>
<style lang="scss">
  .m-single-file__container {
    .drop-zone {
      background-position: center calc(50% - 10px);
      padding: 140px 30px 60px;

      .v-navigation-drawer & {
        background-position: 20px 10px;
        padding: 21px 30px 30px 80px;
        border: 2px dotted #ddd;
        text-align: left;
      }
    }

    .m-single-file {
      &__contents {
        background-color: #eee;
        padding: 20px;
      }

      &__list {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      &__file {
        display: flex;
        position: relative;
      }

      &__image {
        width: 100px;
        flex-grow: 0;
        height: auto;
        margin-right: 10px;
        border-radius: 5px;
        box-shadow: 2px 1px 5px rgba(0,0,0,.3);
      }

      &__file-details {
        width: calc(100% - 120px);

        h4 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
          width: 100%;
        }
      }

      &__action {
        flex-grow: 0;
        position: absolute;
        right: -25px;
        bottom: -10px;
      }
    }

    .v-icon {
      margin-right: 10px;
      opacity: 1;
    }

    .m-single-file__filezone {
      display: flex;
      justify-content: space-between;
      margin-top: 20px
    }

    .v-progress-linear {
      margin-top: -3px;
    }

    .drop-active {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: fixed;
      z-index: 9999;
      opacity: .6;
      text-align: center;
      background: #000;
    }

    .drop-active h3 {
      margin: -.5em 0 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      font-size: 40px;
      color: #fff;
      padding: 0;
    }
  }
</style>

<script>
import mUploadMixin from './uploadMixin'

export default {
  name: 'mFileAvatar',
  mixins: [mUploadMixin]
}
</script>
