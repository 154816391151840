<template>
  <v-text-field
    class="m-url"
    type="text"
    :ref="fieldData.model"
    v-model="proxyValue"
    :label="fieldData.label"
    :required="fieldData.required"
    persistent-hint
    :hint="url"
  >
  </v-text-field>
</template>
<script>
import mMixin from './mixin'
import { mapGetters } from 'vuex'
import slugify from 'slugify'

export default {
  name: 'm-url',
  mixins: [mMixin],
  computed: {
    ...mapGetters({
      selectedItem: 'crud/selectedItem'
    }),
    url () {
      return this.currentUser.account.baseUrl + this.proxyValue
    }
  },
  mounted () {
    const urlContainer = document.querySelector('.m-url .v-text-field__details .v-messages__message')
    if (urlContainer) {
      urlContainer.addEventListener('click', () => {
        window.open(this.url)
      })
    }

    if (!this.value) this.createSlug()
  },
  methods: {
    createSlug () {
      // console.log('This item doesn\'t have slug. Creating a new one!')
      const title = this.selectedItem.title
      const slug = slugify(title, {
        lower: true,
        remove: /[*+~.()'"!:@]/g
      })
      this.proxyValue = slug
    }
  }
}
</script>
<style lang="scss">
  .m-url {
    .v-text-field__details .v-messages__message {
      &::before {
        content: 'URL Final: ';
        color: #1976d2;
      }

      cursor: pointer;
    }
  }
</style>
