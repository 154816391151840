<template>
<div class="example-full">
  <h3 class="mb-4">{{ fieldData.label }}</h3>

  <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
    <h3>Arraste os arquivos aqui para enviar</h3>
  </div>

  <div class="upload" v-show="!isOption">
    <div class="table-responsive">
      <div v-if="!value || !value.length" class="drop-zone">
        <h4>Arraste os arquivos aqui</h4>
      </div>
      <v-layout row wrap>
        <v-flex
          v-for="(file, idx) in value"
          :key="idx"
        >
          <v-card>
            <a :href="file.url" target="_blank" v-if="file.uploaded">
              <v-img
                :src="file.url"
                @click="window.open('', file.url)"
              />
            </a>
            <v-img
              v-else
              :src="file.thumb"
            />

            <v-card-text v-if="!file.uploaded">
              <span v-if="file.name">{{ file.name }}</span><br>
              <span v-if="file.size">{{ `${Math.round(file.size/1024)}Kb` }}</span>
            </v-card-text>

            <v-card-text v-else>
              <v-text-field
                v-model="file.title"
                label="Título"
              />
              <v-text-field
                v-model="file.url"
                label="URL"
                disabled
              />
              <v-text-field
                v-model="file.link"
                label="Link"
              />
              <v-text-field
                v-model="file.position"
                label="Posição"
              />

            </v-card-text>

            <v-card-actions>
              <v-chip v-if="file.uploaded" color="teal" text-color="white">
                <v-avatar>
                  <v-icon>check_circle</v-icon>
                </v-avatar>
                UPLOADED
              </v-chip>
              <v-icon @click.prevent="removeItem(file)">delete</v-icon>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </div>

    <div class="example-foorer">
      <div class="btn-group">
        <file-upload
          class="v-btn theme--light default bt-selecionar-images"
          :extensions="extensions"
          :accept="accept"
          :multiple="multiple"
          :directory="directory"
          :size="size || 0"
          :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
          :data="data"
          :drop="drop"
          :drop-directory="dropDirectory"
          :add-index="addIndex"
          v-model="value"
          ref="upload"
        >
          Adicionar arquivos
        </file-upload>

        <v-btn
          @click="upload()"
          class="theme--light default bt-selecionar-images"
          :disabled="disableUploadButton"
        >
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
          Upload
        </v-btn>
      </div>
    </div>
  </div>
</div>

</template>
<style lang="scss">
  .example-full {
    width: 100%;

    .btn-group {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      font-weight: normal;
    }

    .btn-group .dropdown-menu {
      display: block;
      visibility: hidden;
      transition: all .2s;
    }

    .btn-group:hover > .dropdown-menu {
      visibility: visible;
    }

    label.dropdown-item {
      margin-bottom: 0;
    }

    .btn-group .dropdown-toggle {
      margin-right: .6rem;
    }

    .filename {
      margin-bottom: .3rem;
    }

    .btn-is-option {
      margin-top: 0.25rem;
    }

    .example-foorer {
      padding: .5rem 0;
      border-top: 1px solid #e9ecef;
      border-bottom: 1px solid #e9ecef;
    }

    .edit-image img {
      max-width: 100%;
    }

    .edit-image-tool {
      margin-top: .6rem;
    }

    .edit-image-tool .btn-group {
      margin-right: .6rem;
    }

    .footer-status {
      padding-top: .4rem;
    }

    .drop-active {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: fixed;
      z-index: 9999;
      opacity: .6;
      text-align: center;
      background: #000;
    }

    .drop-active h3 {
      margin: -.5em 0 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      font-size: 40px;
      color: #fff;
      padding: 0;
    }
  }

  .bt-selecionar-images {
    display: inline-flex !important;
    align-items: center;
    width: auto;
    margin: 0 10px 0 0;
  }

  .drop-zone {
    border: 2px dotted #ddd;
    padding: 100px 30px;
    background-color: #f0f0f0;
    text-align: center;
    margin-top: 15px;
  }

  .theme--light.v-sheet {
    background-color: #ddd;
  }

  .v-image__image {
    background-color: #e5e5e5;
  }
</style>

<script>
import uploadService from '@/services/UploadService'
import FileUpload from 'vue-upload-component'
import mMixin from '@/components/mComponents/mixin'

export default {
  name: 'mSimpleGallery',
  components: {
    FileUpload
  },
  data () {
    return {
      accept: 'image/png,image/gif,image/jpeg,image/webp',
      extensions: 'gif,jpg,jpeg,png,webp',
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      thread: 3,
      name: 'file',
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      data: {
      },
      addData: {
        show: false,
        name: '',
        type: '',
        content: ''
      },
      editFile: {
        show: false,
        name: ''
      },
      uploading: false
    }
  },
  computed: {
    disableUploadButton () {
      if (this.uploading) return true
      return this.value && this.value.filter(item => !item.uploaded).length === 0
    },
    entity () {
      return this.$route.meta.entity
    }
  },
  mounted () {
    this.value = this.proxyValue
  },
  methods: {
    async upload () {
      this.uploading = true

      const collection = this.entity.collection
      const response = await uploadService(collection).uploadFiles(this.value)

      if (response) {
        this.value = await response
      }
      this.uploading = false
    },
    async removeItem (file) {
      const collection = this.entity.collection

      if (file.uploaded) {
        await uploadService(collection).deleteFile(file.path)
      }

      this.value = this.value.filter(item => item.id !== file.id)
    }
  },
  mixins: [mMixin]
}
</script>
