const entity = {
  name: {
    singular: 'Driver',
    plural: 'Drivers'
  },
  collection: 'drivers',
  tableHeaders: [
    { text: 'Name', value: 'name' },
    { text: 'MobilePhone', value: 'mobilePhone' },
    { text: 'Phone', value: 'landline' },
    { text: 'Email', value: 'email' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    name: '',
    email: '',
    phone: '',
    mobilePhone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    notes: '',
    loads: [],
    carrier: ''
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      { label: 'Carrier', model: 'carrier', type: 'select', multiple: false, collection: 'carriers', size: 'lg12' },
      { label: 'Notes', model: 'notes', size: 'lg12', type: 'textarea' }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Details',
        fields: [
          { label: 'Name', model: 'name', size: 'lg12' },
          { label: 'Email', model: 'email', size: 'lg6' },
          { label: 'Phone', model: 'phone', size: 'lg3' },
          { label: 'Mobile Phone', model: 'mobilePhone', size: 'lg3' },
          { label: 'Address', model: 'address', size: 'lg12' },
          { label: 'City', model: 'city', size: 'lg6' },
          { label: 'State', model: 'state', size: 'lg3' },
          { label: 'Zip', model: 'zip', size: 'lg3' }
        ]
      },
      {
        name: 'Loads',
        groupType: 'list',
        entity: 'loads',
        itemsPerPage: 50,
        fields: ['orderNumber', 'status'],
        tableHeaders: [
          { text: 'orderNumber', value: 'orderNumber' },
          { text: 'status', value: 'status' },
          { text: 'Actions', value: 'action', sortable: false }
        ],
        relatedCollection: 'driver',
        relatedCollectionIdField: 'driver',
        editOnDrawer: true
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/drivers',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'drivers',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/drivers/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'drivers',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/drivers/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'drivers',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }
]
