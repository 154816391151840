<template>
  <div class="field-token" v-if="this.$route.params.id">
    <v-text-field
      :ref="fieldData.model"
      v-model="proxyValue"
      :label="fieldData.label"
      :required="fieldData.required"
    />
  </div>
</template>

<script>
import axios from 'axios'
import mMixin from './mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [mMixin],
  computed: {
    ...mapGetters({
      selectedItem: 'crud/selectedItem'
    })
  },
  async mounted () {
    if (this.$route.params.id && !this.value) {
      const payload = {
        id: this.$route.params.id,
        name: this.selectedItem.name
      }

      const token = await axios.post(process.env.VUE_APP_ENV_API + 'auth/generate-client-id', payload)
      this.proxyValue = token.data.key
    }
  }
}
</script>
<style lang="stylus">

</style>
