<template>
  <v-checkbox
    :ref="fieldData.model"
    :value="inputModel"
    :label="fieldData.label"
    :required="fieldData.required"
    @input="emitInput"
  />
</template>
<script>
import mMixin from '@/components/mComponents/mixin'

export default {
  name: 'm-checkbox',
  mixins: [mMixin]
}
</script>
