const entity = {
  name: {
    singular: 'Pesquisa',
    plural: 'Pesquisas'
  },
  collection: 'forms',
  tableHeaders: [
    { text: 'Título', value: 'title' },
    { text: 'Início', value: 'initialDate' },
    { text: 'Término', value: 'endDate' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    logotype: '',
    title: null,
    subtitle: null,
    description: null,
    backgroundColor: '',
    isRequired: true,
    slug: '',
    initialDate: '',
    endDate: '',
    mailing: '',
    questions: [],
    nextStep: 'next'
  },
  schema: {
    groups: [
      {
        name: 'Pesquisa',
        fields: [
          {
            type: 'text-field',
            label: 'Título',
            model: 'title',
            required: true,
            size: 'xs6'
          },
          {
            type: 'text-field',
            label: 'Subtítulo',
            model: 'subtitle',
            required: false,
            size: 'xs6'
          },
          {
            type: 'text-field',
            label: 'Descrição',
            model: 'description',
            required: false,
            size: 'xs12'
          },
          {
            type: 'text-field',
            label: 'Url Amigável',
            model: 'slug',
            required: true,
            size: 'xs4'
          },
          {
            type: 'text-field',
            label: 'Cor',
            model: 'backgroundColor',
            required: true,
            size: 'xs2'
          },
          {
            type: 'date-picker',
            label: 'Início',
            model: 'initialDate',
            required: true,
            size: 'xs3'
          },
          {
            type: 'date-picker',
            inputType: 'text',
            label: 'Término',
            model: 'endDate',
            required: true,
            size: 'xs3'
          },
          {
            type: 'file',
            label: 'Arquivos',
            model: 'logotype',
            required: true
          }
        ]
      },
      {
        name: 'Perguntas',
        fields: [{
          label: 'Perguntas',
          model: 'questions',
          type: 'array',
          arrayOf: {
            fields: [{
              type: 'text-field',
              inputType: 'text',
              label: 'Pergunta',
              model: 'question',
              required: true.valueOf,
              size: 'xs12'
            }, {
              type: 'select',
              label: 'Tipo da Resposta',
              model: 'answerType',
              required: true,
              items: [
                { value: 'text', text: 'Texto' },
                { value: 'choice', text: 'Alternativa' },
                { value: 'multiple', text: 'Múltipla escolha' }
              ],
              size: 'xs3'
            }, {
              type: 'select',
              label: 'Próximo passo',
              model: 'nextStep',
              required: true,
              items: [
                { value: 'next', text: 'Próxima pergunta' },
                { value: 'submit', text: 'Submeter formulário' },
                { value: 'depends', text: 'Depende da resposta' }
              ],
              size: 'xs3'
            }, {
              type: 'text-field',
              inputType: 'number',
              label: 'Posição',
              model: 'position',
              required: true,
              size: 'xs1'
            }, {
              type: 'switch',
              label: 'Obrigatório',
              model: 'isRequired',
              valueOn: true,
              valueOff: false,
              size: 'xs2'
            }, {
              type: 'array',
              label: 'Respostas',
              model: 'answers',
              required: true,
              condition: data => data['answerType'] === 'multiple' || data['answerType'] === 'choice',
              arrayOf: {
                fields: [{
                  type: 'text-field',
                  inputType: 'text',
                  label: 'Resposta',
                  model: 'answer',
                  required: true
                }, {
                  type: 'select',
                  label: 'Ir para',
                  model: 'nextQuestion',
                  required: true,
                  items: [
                    { value: 'next', text: 'Próxima pergunta' },
                    { value: 'submit', text: 'Submeter formulário' }
                  ],
                  size: 'xs3'
                }]
              },
              size: 'xs12'
            }]
          }
        }]
      },
      {
        name: 'Resultados',
        list: 'results'
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/forms',
    meta: {
      entity,
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "forms" */ '../../../modules/forms/List.vue')
  }, {
    path: '/forms/new',
    meta: {
      entity,
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "forms" */ '../../../modules/forms/Form.vue')
  }, {
    path: '/forms/:id',
    meta: {
      entity,
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "forms" */ '../../../modules/forms/Form.vue')
  }
]
