import firebase from 'firebase'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_KEY,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
// const firestore = firebaseApp.firestore()
// const firebaseAuth = firebaseApp.auth()
const firebaseStorage = firebaseApp.storage()

export {
  firebaseApp,
  // firestore,
  // firebaseAuth,
  firebaseStorage
}
