const entity = {
  name: {
    singular: 'Menu',
    plural: 'Menus'
  },
  collection: 'menus',
  tableHeaders: [
    { text: 'Título', value: 'title' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    title: null,
    key: '',
    items: []
  },
  config: {
    name: 'Config',
    fields: [
      {
        type: 'text-field',
        label: 'Título',
        model: 'title',
        required: true,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'text-field',
        label: 'Chave',
        model: 'key',
        required: true,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'date-picker',
        label: 'Data de Entrada',
        model: 'date_in',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'date-picker',
        label: 'Data de Saída',
        model: 'date_out',
        required: false,
        size: 'xs12 sm12 md6 lg6'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado'
      }
    ]
  },
  schema: {
    fields: [
      {
        label: 'Items',
        model: 'items',
        type: 'array',
        translations: {
          title: 'Título',
          description: 'URL',
          position: 'Posição'
        },
        arrayOf: {
          fields: [{
            type: 'text-field',
            inputType: 'text',
            label: 'Título',
            model: 'title'
          }, {
            type: 'text-field',
            inputType: 'text',
            label: 'URL',
            model: 'description'
          }, {
            type: 'text-field',
            inputType: 'text',
            label: 'Ícone',
            model: 'icon'
          }, {
            type: 'switch',
            model: 'target',
            label: 'Nova janela'
          }, {
            type: 'text-field',
            inputType: 'number',
            label: 'Posição',
            model: 'position'
          }]
        }
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/menus',
    name: 'menus',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'menus',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: "menus" */ '../../../views/Crud.vue')
  }, {
    path: '/menus/new',
    name: 'menus',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'menus',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: "menus" */ '../../../views/Crud.vue')
  }, {
    path: '/menus/:id',
    name: 'menus',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'menus',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: "menus" */ '../../../views/Crud.vue')
  }
]
