<template>
  <v-switch
    :ref="fieldData.model"
    v-model="proxyValue"
    :label="correctLabel"
    :required="fieldData.required"
    :trueValue="fieldData.textOn"
    color="success"
  />
</template>
<script>
import mMixin from './mixin'

export default {
  name: 'm-switch',
  mixins: [mMixin],
  computed: {
    correctLabel () {
      let value = this.fieldData.label

      if (Boolean(this.proxyValue) && this.fieldData.textOn) value = this.fieldData.textOn
      if (!this.proxyValue && this.fieldData.textOff) value = this.fieldData.textOff

      return value
    }
  }
}
</script>
