export default [
  {
    path: '/media-manager',
    meta: {
      requiresAuth: true,
      requiresPermissionTo: 'media-manager'
    },
    component: () => import(/* webpackChunkName: "files" */ '../../../views/FileManager.vue')
  }
]
