<template>
  <v-snackbar
    v-model="showSnackbar"
    multi-line
    top
    :timeout="timeout"
  >
    {{ message }}
    <v-btn
      color="pink"
      text
      @click="hideMessage()"
    >Close</v-btn>
  </v-snackbar>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'm-messages',
  props: {
    timeout: {
      required: false,
      type: Number,
      default: 60000
    }
  },
  data () {
    return {
      showSnackbar: false
    }
  },
  watch: {
    message (val) {
      if (val) this.showMessage(val)
    }
  },
  computed: {
    ...mapGetters('messages', ['message'])
  },
  methods: {
    ...mapActions({
      defineMessage: 'messages/defineMessage',
      clearMessage: 'messages/clearMessage'
    }),
    showMessage (val) {
      // console.log('showing message: ', val)
      this.showSnackbar = true
      this.defineMessage(val)

      setTimeout(() => {
        this.hideMessage()
        // console.log('hiding message')
      }, this.timeout)
    },
    hideMessage () {
      this.showSnackbar = false
      this.clearMessage()
    }
  }
}
</script>
