<template>
  <section>
    <h3 class="mb-4">{{ fieldData.label }}</h3>
    <editor api-key="no-api-key" :init="editorConfig" v-model="proxyValue" />
  </section>
</template>
<script>
import mMixin from '@/components/mComponents/mixin'
import Editor from '@tinymce/tinymce-vue'
import language from '@/assets/languages/pt_BR'
(function () {
  const thisInterval = setInterval(() => {
    const isDone = Boolean(window.tinymce)
    if (isDone) {
      window.tinymce.addI18n('pt_BR', language)
      clearInterval(thisInterval)
    }
  }, 1000)
})()

export default {
  name: 'm-editor',
  data () {
    return {
      editorData: null,
      editorConfig: {
        height: 600,
        menubar: false,
        language: 'pt_BR',
        entity_encoding: 'raw',
        relative_urls: false,
        convert_urls: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo redo | formatselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | image link | bullist numlist outdent indent | removeformat | fullscreen code'
      },
      imageButtons: []
    }
  },
  components: {
    Editor
  },
  mixins: [mMixin],
  mounted () {
    this.$nextTick(() => {
      const el = document.querySelector('.tox-tinymce-aux')

      const mutationObserver = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          Array.from(mutation.addedNodes).map((item, idx) => {
            if (item.querySelector) {
              const modalTitle = item.querySelector('.tox-dialog__title')
              const buttonExists = document.querySelector('#mediaManagerButton')

              if (!buttonExists && modalTitle && modalTitle.textContent === 'Inserir/editar imagem') {
                const button = document.createElement('button')
                button.textContent = 'Media Manager'
                button.setAttribute('id', 'mediaManagerButton')
                button.addEventListener('click', () => {
                  const url = window.location.href.split('#').shift() + '#/media-manager'
                  window.open(url)
                })
                const adjacentElement = document.querySelector('.tox-dialog__body .tox-form')
                const container = document.querySelector('.tox-dialog__body-content')
                container.insertBefore(button, adjacentElement)
              }
              return item
            }
          })
        })
      })

      const testingElementInterval = setInterval(testElement(), 1000)
      function testElement () {
        if (el) {
          mutationObserver.observe(el, {
            childList: true,
            subtree: true
          })
          clearInterval(testingElementInterval)
        }
      }
    })
  }
}
</script>
<style lang="scss">
.tox-notifications-container {
  display: none;
}

.tox.tox-tinymce {
  width: 100%;

  .tox-status-bar {
    height: 33px;
  }
}

.vue-form-generator .field-wrap .tox button {
  margin: 0;
  border: none;
  padding: inherit;
  display: flex;
}

textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.ck.ck-editor {
  width: 100%;

  .ck-editor__editable {
    min-height: 500px;
  }

  .ck-editor__editable {
    table {
      width: 100%;
      table-layout: fixed;

      td {
        vertical-align: top;
      }
    }
  }
}

#mediaManagerButton {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
  color: #4CAF50 !important;
  caret-color: #4CAF50 !important;
  font-size: 0.875rem;
  border: thin solid currentColor;
  width: 150px;
  text-align: center;
  border-radius: 3px;
}
</style>
