const entity = {
  name: {
    singular: 'Pai',
    plural: 'Pais'
  },
  collection: 'parents',
  tableHeaders: [
    { text: 'Nome', value: 'name' },
    { text: 'CPF', value: 'document' },
    { text: 'Telefone', value: 'landline' },
    { text: 'Celular', value: 'cellphone' },
    { text: 'Email', value: 'email' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    name: '',
    email: '',
    document: '',
    cellphone: '',
    landline: '',
    zipcode: '',
    city: '',
    state: '',
    address: '',
    addressNumber: '',
    address2: '',
    neighborhood: '',
    country: '',
    buyDate: ''
  },
  config: {
    name: 'Config',
    fields: [
    ]
  },
  schema: {
    groups: [
      {
        name: 'Dados pessoais',
        fields: [
          {
            type: 'text-field',
            label: 'Nome',
            model: 'name',
            size: 'xs12 sm12 md12 lg12'
          },
          {
            type: 'text-field',
            label: 'CPF',
            model: 'document',
            size: 'xs12 sm12 md6 lg6',
            inputMask: '###.###.###-##',
            outputMask: '###########'
          },
          {
            type: 'text-field',
            label: 'Email',
            model: 'email',
            size: 'xs12 sm12 md6 lg6'
          },
          {
            type: 'text-field',
            label: 'Telefone',
            model: 'landline',
            size: 'xs12 sm12 md6 lg6',
            inputMask: '(##) ####-####',
            outputMask: '###########'
          },
          {
            type: 'text-field',
            label: 'Celular',
            model: 'cellphone',
            size: 'xs12 sm12 md6 lg6',
            inputMask: '(##) #####-####',
            outputMask: '###########'
          },
          {
            type: 'text-field',
            label: 'CEP',
            model: 'zipcode',
            size: 'xs12 sm12 md3 lg3',
            inputMask: '#####-###',
            outputMask: '########'
          },
          {
            type: 'text-field',
            label: 'Endereço',
            model: 'address',
            size: 'xs12 sm12 md8 lg8'
          },
          {
            type: 'text-field',
            label: 'Número',
            model: 'number',
            size: 'xs12 sm12 md1 lg1'
          },
          {
            type: 'text-field',
            label: 'Complemento',
            model: 'address2',
            size: 'xs12 sm12 md3 lg3'
          },
          {
            type: 'text-field',
            label: 'Bairro',
            model: 'neighborhood',
            size: 'xs12 sm12 md3 lg3'
          },
          {
            type: 'text-field',
            label: 'Cidade',
            model: 'city',
            size: 'xs12 sm12 md5 lg5'
          },
          {
            type: 'text-field',
            label: 'Estado',
            model: 'state',
            size: 'xs12 sm12 md1 lg1'
          }
        ]
      },
      {
        name: 'Alunos',
        groupType: 'list',
        entity: 'students',
        itemsPerPage: 50,
        fields: ['picture', 'name', 'studentId', 'landline', 'cellphone', 'email'],
        relatedCollection: 'parents',
        relatedCollectionIdField: 'students',
        addButton: false,
        tableHeaders: [
          { text: 'Foto', value: 'picture', type: 'avatar' },
          { text: 'Nome', value: 'name' },
          { text: 'RA', value: 'studentId' },
          { text: 'Telefone', value: 'landline' },
          { text: 'Celular', value: 'cellphone' },
          { text: 'Email', value: 'email' }
        ],
        editOnDrawer: false
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/parents',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'parents',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/parents/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'parents',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/parents/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'parents',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }
]
