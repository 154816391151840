import '@babel/polyfill'
import Vue from 'vue'

// External Libraries
import Vuelidate from 'vuelidate'
import vuetify from '@/plugins/vuetify'
import '@/plugins/vuetify-mask'

import VuetifyFileManager from '@kingscode/vuetify-file-manager'

// Local Plugins
import VueCookies from 'vue-cookies'

// mComponents
import mInput from '@/components/mComponents/m-input'
import mMessages from '@/components/mComponents/m-messages'
import mTextarea from '@/components/mComponents/m-textarea'
import mDate from '@/components/mComponents/m-date'
import mDateRange from '@/components/mComponents/m-date-range'
import mArray from '@/components/mComponents/m-array'
import mCombobox from '@/components/mComponents/m-combobox'
import mSelect from '@/components/mComponents/m-select'
import mCheckbox from '@/components/mComponents/m-checkbox'
import mSwitch from '@/components/mComponents/m-switch'
import mRadio from '@/components/mComponents/m-radio'
import mEditor from '@/components/mComponents/m-editor'
import mCodeEditor from '@/components/mComponents/m-code-editor'
import mToolbar from '@/components/mComponents/m-toolbar'
import mSingleFile from '@/components/mComponents/m-single-file'
import mMultipleFiles from '@/components/mComponents/m-multiple-files'
import mImage from '@/components/mComponents/m-image'
import mToken from '@/components/mComponents/m-token'
import mTable from '@/components/mComponents/m-table'
import mSimpleGallery from '@/components/mComponents/m-simple-gallery'
import mGalleryCards from '@/components/mComponents/m-gallery-cards'
import mUrl from '@/components/mComponents/m-url'
import mFileAvatar from '@/components/mComponents/m-file-avatar'

// App Level
import { router } from './router/index'
import store from './store/store'

import App from './App.vue'
import './registerServiceWorker'

Vue.use(VueCookies)
Vue.use(Vuelidate)
Vue.use(VuetifyFileManager)

Vue.component('m-input', mInput)
Vue.component('m-messages', mMessages)
Vue.component('m-textarea', mTextarea)
Vue.component('m-array', mArray)
Vue.component('m-combobox', mCombobox)
Vue.component('m-select', mSelect)
Vue.component('m-date', mDate)
Vue.component('m-date-range', mDateRange)
Vue.component('m-switch', mSwitch)
Vue.component('m-checkbox', mCheckbox)
Vue.component('m-radio', mRadio)
Vue.component('m-switch', mSwitch)
Vue.component('m-editor', mEditor)
Vue.component('m-code-editor', mCodeEditor)
Vue.component('m-toolbar', mToolbar)
Vue.component('m-single-file', mSingleFile)
Vue.component('m-multiple-files', mMultipleFiles)
Vue.component('m-image', mImage)
Vue.component('m-token', mToken)
Vue.component('m-table', mTable)
Vue.component('m-simple-gallery', mSimpleGallery)
Vue.component('m-gallery-cards', mGalleryCards)
Vue.component('m-url', mUrl)
Vue.component('m-file-avatar', mFileAvatar)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify
}).$mount('#app')
