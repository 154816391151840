const entity = {
  name: {
    singular: 'Broker',
    plural: 'Brokers'
  },
  collection: 'brokers',
  tableHeaders: [
    { text: 'Name', value: 'name' },
    { text: 'Contact Name', value: 'contactName' },
    { text: 'Contact Email', value: 'contactEmail' },
    { text: 'Contact Phone', value: 'contactPhone' },
    { text: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    name: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    contactMobilePhone: '',
    billingName: '',
    billingEmail: '',
    billingPhone: '',
    billingMobilePhone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    notes: ''
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      { label: 'Name', model: 'name', size: 'lg12' },
      { label: 'address', model: 'address', size: 'lg12' },
      { label: 'city', model: 'city', size: 'lg6' },
      { label: 'state', model: 'state', size: 'lg3' },
      { label: 'zip', model: 'zip', size: 'lg3' },
      { label: 'Notes', model: 'notes', size: 'lg12', type: 'textarea' }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Contact Info',
        fields: [
          { type: 'text-field', label: 'Name', model: 'contactName', size: 'lg12' },
          { type: 'text-field', label: 'Email', model: 'contactEmail', size: 'lg12' },
          { type: 'text-field', label: 'Phone', model: 'contactPhone', size: 'lg6' },
          { type: 'text-field', label: 'Mobile Phone', model: 'contactMobilePhone', size: 'lg6' }
        ]
      },
      {
        name: 'Billing Info',
        fields: [
          { type: 'text-field', label: 'Name', model: 'billingName', size: 'lg12' },
          { type: 'text-field', label: 'Email', model: 'billingEmail', size: 'lg12' },
          { type: 'text-field', label: 'Phone', model: 'billingPhone', size: 'lg6' },
          { type: 'text-field', label: 'Mobile Phone', model: 'billingMobilePhone', size: 'lg6' }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default [
  {
    path: '/brokers',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'brokers',
      crud: 'list'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/brokers/new',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'brokers',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }, {
    path: '/brokers/:id',
    meta: {
      entity,
      requiresAuth: true,
      requiresPermissionTo: 'brokers',
      crud: 'form'
    },
    component: () => import(/* webpackChunkName: 'banners' */ '../../../views/Crud.vue')
  }
]
